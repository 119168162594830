<!-- Order -->
<template>
<div>
<div slot="header" class="modal-header">
			<span class="modal-title-popup">{{label.title}}</span>
			<i style="float: right; padding: 3px 0" class="model-close el-icon-close icon-pointer" @click.prevent="closeForm"></i>
		</div>
    <div class="card card-custom">
        <div class="card-body p-0">
            <!--begin: Orders-->
            <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
                <!--begin: Order Body-->
                <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
                    <div class="col-xl-12">
                        <!--begin: Orders Form-->
                        <el-form ref="form" :model="form" auto-complete="false">  
                            <el-row class="pricing-section">                          
                              <el-col :span="7" >
                                 <el-form-item label="Credit Limit" prop="credit_limit">
                                    <el-input-number v-model="form.credit_limit" auto-complete="off" :min="0"></el-input-number>
                                    <span class="form-err" v-if="form.errors.has('credit_limit')" >{{form.errors.get('credit_limit')}}</span>
                                 </el-form-item>                                 
                              </el-col>                             
                              <el-col :span="7" :offset="1">
                                 <el-form-item label="Available Credit" prop="available_credit">
                                    <el-input-number v-model="form.available_credit" auto-complete="off" :min="0"></el-input-number>
                                    <span class="form-err" v-if="form.errors.has('available_credit')" >{{form.errors.get('available_credit')}}</span>
                                 </el-form-item>                                 
                              </el-col>   
                              <el-col :span="7" :offset="1">
                                 <el-form-item label="Balance Credit" prop="balance_showing">
                                    <el-input-number v-model="form.balance_showing" auto-complete="off" :min="0"></el-input-number>
                                    <span class="form-err" v-if="form.errors.has('balance_showing')" >{{form.errors.get('balance_showing')}}</span>
                                 </el-form-item>                                 
                              </el-col>   
                           </el-row>                     
                          <el-row>
                           <el-form-item style="text-align:center">
                              <el-button type="primary" @click="updateCredit()" style="color: #ffffff;">{{btn_text}}</el-button>
                             
                           </el-form-item>
                          </el-row>
                        </el-form>
                        <!--end: Orders Form-->
                    </div>
                </div>
                <!--end: Orders Body-->
            </div>
        </div>
        <!--end: Orders-->
    </div>
</div>
</template>

<script>

import Form from '@/core/services/api.form.services';
import { globalEvents } from '@/utils/globalEvents';
import {getCustomer} from "@/api/customer";
export default {
  name: 'add-order',
  props: {
    customer_id: {
              type: Number,             	
	    },
  label:{
	      type: Object,
	      default() {
	        return {button:'Create',title:'Customer Credit'}
	      }
	    }
  },
    data() {
    return {
      loading:false,
      error: null,
      btn_text : 'Add',
      form : new Form({
      
        customer_id:null,
        credit_limit:null,
        available_credit:null,
        balance_showing: null,      
      }),       
      credit_list: [],     
     api_url :'customer/credit/',
    }
  },
  components: {},
  mounted() {
   
    this.form.customer_id = this.customer_id
  },

  methods: {
     closeForm() {
	      	if (!this.form.submiting) {
	        	globalEvents.$emit('CreditAdded', false)
	        	this.$emit('close')
	      	}
	    },
    
    getCustomerCredit(){       
      this.loading = true
      getCustomer(this.customer_id).then(response => {         
        this.credit_list = response.data.data[0].customer_credit 
        console.log(this.credit_list);      
        this.loading = false
      })
    },
     updateCredit: function() {                
      this.loading=true;
       this.api_url = 'customer/credit/' + this.form.customer_id
       this.form.post(this.api_url).then((response) => {
        if(response.status){
          	globalEvents.$emit('CreditAdded', response.data)
	        	this.$emit('close')
            //this.$router.push({ path: '/customer/view/' })
            this.loading=false;
            this.$showResponse('success', response.message);
        }
      })
    }, 
   
  },
  
  created(){
    //console.log(this.inventory_id);
    this.form.customer_id = this.customer_id
    let customerId = this.form.customer_id;
    
    if(parseInt(customerId)>0){
        this.btn_text = 'Update'
        this.loading = true
      getCustomer(this.customer_id).then(response => {         
        
         this.form = new Form(response.data.data[0].customer_credit[0])    
        this.loading = false
      })
    }
    
  },
  
}
</script>

<style>
 
 .vm--modal {    
    overflow: unset !important;
}
</style>

