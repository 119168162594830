<template>
 <div>
    <div slot="header" class="modal-header popup-header">
      <span class="modal-title-popup"
        ><i class="fa fa-credit-card" aria-hidden="true"></i
        >{{ label.title }}</span
      >
      <i
        style="float: right; padding: 3px 0"
        class="model-close el-icon-close icon-pointer"
        @click.prevent="closeForm"
      ></i>
    </div>
    <div class="card card-custom" v-loading="loading">
    <div class="card-body p-0">
    <el-form ref="form"  auto-complete="nope">    
      <div class="row">
        <div class="col-sm-4">
            <template>
            <div class="credit-container">
                <el-divider content-position="left"
                >Payment Amount</el-divider
                >
                <h2 class="text-center">
                ${{ recordPayment? parseFloat(recordPayment.amount).toFixed(2):0.00 }}
                </h2>
            </div>
            </template>
        </div>
        <div class="col-sm-4">
            <template>
            <div class="credit-container">
                <el-divider content-position="left"
                >Past Amount</el-divider
                >
                <h2 class="text-center">
                ${{ recordPayment? parseFloat(recordPayment.past_amount).toFixed(2):0.00 }}
                </h2>
            </div>
            </template>
        </div>
        <div class="col-sm-4">
            <template>
            <div class="credit-container">
                <el-divider content-position="left"
                >Balance Amount</el-divider
                >
                <h2 class="text-center">
                ${{ recordPayment? parseFloat(recordPayment.balance).toFixed(2):0.00 }}
                </h2>
            </div>
            </template>
        </div>
        </div>
        <div class="row mt-5">
          <div class="col-xl-12">
            <h4>
              Payment Log Details
            </h4>
          </div>
          <div class="col-xl-12 ">  
              <el-table :data="payment_log" stripe border fit >                                                         
                <el-table-column
                  type="index"
                  width="50">                                    
                </el-table-column>
                <el-table-column label="Created Date" width="130">
                      <template slot-scope="scope">
                        <span>{{payment_log[scope.$index].created_at|formatDate('DD MMM YYYY')}}</span>                                                                                  
                    </template>
                </el-table-column>
                 <el-table-column label="Invoice #" >
                      <template slot-scope="scope">
                        <span>{{payment_log[scope.$index].invoice.invoice_number}}</span>
                      </template>
                 </el-table-column>
                  <el-table-column label="Invoice Amount" width="130" align="center">
                      <template slot-scope="scope">
                        <span>$ {{payment_log[scope.$index].invoice.total_amount}}</span>
                      </template>
                 </el-table-column>
                   <el-table-column label="Term Closed Amount" width="150" align="center">
                      <template slot-scope="scope">
                        <span>$ {{payment_log[scope.$index].term_closed_amount}}</span>
                      </template>
                 </el-table-column>
                  <el-table-column label="Payment Amount" width="130" align="center">
                      <template slot-scope="scope">
                        <span>$ {{payment_log[scope.$index].payment_amount}}</span>
                      </template>
                 </el-table-column>
              </el-table>
          </div>
        </div>
      
        </el-form>
    </div>
  </div>
 
 </div>
</template>


<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { getRecordPaymentLogList} from "@/api/customer";
export default {
   props: {
      recordPayment: {
      type: Object,
    },
    label: {
      type: Object,
      default() {
        return { button: "Create", title: "Record Payment Details" };
      },
    },
  },
  data() {
    return {
      loading: false,
      payment_log:[],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Credits", route: "/customer/credit" },
      { title: "Customer Credits" },
    ]);
  
  },
  methods: {
      closeForm() {     
        this.$emit("close");      
    },
    getRecordPaymentLogList(){
      this.loading = true;
      getRecordPaymentLogList(this.recordPayment.id).then((response) => {            
            this.payment_log = response.data.data
            console.log(this.payment_log,'record');
            this.loading = false
          });
    }

  },

  created(){
   this.getRecordPaymentLogList();
  }
 
};
</script>

<style scoped>
.review-layout {
  border-style: groove;
  padding: 40px;
  border-color: #366092;
}
.el-icon-circle-close {
  font-size: 40px;
}

.document_name {
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
}
</style>
