<template>
  <div>
    <div class="store-credit-details">     
       <!-- <button class="btn rounded-1 btn btn-sm btn-primary font-weight-bold text-uppercase btn-primary mb-4 ml-3" @click.prevent="addStoreCredit">
        <i class="fa fa-file"></i>Pay Now
      </button> -->
       <div class="balance-details row">        
         
           <div class="col-xl-4">
            <div >              
                <span class="el-dropdown-link font-size-h6  font-weight-bold">
                 <i class="el-icon-s-finance primary" style="color:#3699ff;font-size:25px;"></i> Record Pay Amount
                </span>               
              </div>
            <div class="font-size-h4 font-weight-bolder ml-7">$ {{record_total}}</div>
          </div>
           <div class="col-xl-4">
             <div >
              <el-dropdown>
                <span class="el-dropdown-link font-size-h6  font-weight-bold">
                 <i class="el-icon-s-finance primary" style="color:#3699ff;font-size:25px;"></i> Record Pay Overpayment<i class="el-icon-more el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown" trigger="click">
                  <el-dropdown-item icon="el-icon-check" @click.native="clearInvoice(recordPayment,'Record Payment')"  v-if="amount">Clear Invoice</el-dropdown-item>                 
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            <!-- <div class="font-size-h5 font-weight-bold"><i class="flaticon-notepad " style="color:#3699ff;font-size:25px;"></i> Amount </div>  -->
            <div class="font-size-h4 font-weight-bolder ml-7">$ {{(amount) ? parseFloat(amount).toFixed(2) : '0.00'}}</div>
          </div>
          <div class="col-xl-4">
            <div class="font-size-h5 font-weight-bold"><i class="flaticon-notepad " style="color:#3699ff;font-size:25px;"></i>Balance</div> 
            <div class="font-size-h4 font-weight-bolder ml-7">$ {{(balance_amount) ? parseFloat(balance_amount).toFixed(2) : '0.00'}}</div>
          </div>
       </div>
     
    </div>
    <el-tabs class="root-tab" type="card" v-model="activeTab" @tab-click="handleClick">      
      <el-tab-pane label="Outstanding Invoice" name="out_invoice"> 
           <el-table :data="outstanding_invoices" border element-loading-text="Loading" style="width: 100%" v-loading="loading">
            <el-table-column align="center" label='Status' prop="id" sortable width="140">
              <template slot-scope="scope">
                  <div v-html="getPaymentStatus(scope.row)"></div>
              </template>
            </el-table-column>            
            <!-- <el-table-column align="center" label='Due' prop="id"  width="140">
              <template slot-scope="scope">
                {{scope.row.payment_due_date | formatDate}}
              </template>
            </el-table-column> -->
            <el-table-column align="center" label='Invoice #' prop="id"  width="140" >
              <template slot-scope="scope">
                  <a href="#" @click.prevent="viewInvoice(scope.row.order_id)">
                    {{scope.row.invoice_number ? scope.row.invoice_number : ""}}
                  </a>              
              </template>
            </el-table-column>
            <el-table-column align="center" label='Order #' prop="id"  width="140" >
              <template slot-scope="scope">                
                  <a href="#" @click.prevent="viewOrder(scope.row.order.id)">
                    {{scope.row.order.order_number ? scope.row.order.order_number: ""}}
                  </a>                         
              </template>
            </el-table-column>          
            <el-table-column align="center" label='Invoice Amount' prop="id" sortable width="140" >
              <template slot-scope="scope">
              {{(scope.row.order.total) ? '$'+scope.row.order.total : '$0.00'}}
              </template>
            </el-table-column>
            <el-table-column align="center" label='Amount Due' prop="id" sortable width="140" >
              <template slot-scope="scope">
              {{(scope.row.term_paid_amount) ? '$'+scope.row.term_paid_amount : '$0.00'}}
              </template>
            </el-table-column>
            <el-table-column align="center" label='Balance Due' prop="balance_amount" sortable >
              <template slot-scope="scope">
                $<span v-html="getDueAmount(scope.row)"></span>              
              </template>
            </el-table-column>
         
            <el-table-column fixed="right" align="center" label="Actions" width="80">
              <template slot-scope="scope">
                <el-dropdown @command="handleActionsOutInvoice($event,scope.row)" size="medium">
                  <i class="flaticon2-menu-1"></i>
                  <el-dropdown-menu slot="dropdown" class="action-dropdown">                      
                      <!-- <el-dropdown-item :command="4" v-if="scope.row.invoice_status !='paid'"><i class="fa fa-credit-card"></i>Pay Now</el-dropdown-item> -->
                      <el-dropdown-item :command="1" ><i class="fa fa-eye"></i>View</el-dropdown-item>                      
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
      </el-tab-pane>
      <el-tab-pane label="Payments" name="payment"> 
         <el-row>
      <el-col>
        <el-table :data="payments" element-loading-text="Loading" stripe border fit>
          <el-table-column align="center" label='Payment ID' prop="payment_id" width="100">
            <template slot-scope="scope">
            <span>{{scope.row.id}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label='Invoice ID' prop="order_id" width="120">
            <template slot-scope="scope">
            <span>{{scope.row.invoice.invoice_number}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label='Order Id' prop="order_id" width="120">
            <template slot-scope="scope">
            <span>{{scope.row.invoice.order.order_number}}</span>
            </template>
          </el-table-column>          
          <el-table-column align="center" label='Payment Date' prop="order_number" width="130">
            <template slot-scope="scope">
            <span>{{scope.row.created_at | formatDate}}</span>
            </template>
          </el-table-column>
          <el-table-column label="Payment Method" prop="Payment Method" >
             <template slot-scope="scope">
               <div v-if="scope.row.payment_method == 'Credit Card'">
                    <span>{{scope.row.payment_method}}</span><span class="ml-2">{{scope.row.brand}}**** {{scope.row.last_4}}</span>
               </div>  
               <div v-else>
                  <span>{{scope.row.payment_method}}</span>
               </div>          
            </template>
          </el-table-column>
          <el-table-column label="Amount" prop="Amount Received" width="120" align="center">
            <template slot-scope="scope">
            <span>$ {{scope.row.due_amount}}</span>
            </template>
          </el-table-column>
           <el-table-column label="Verified" prop="verified_by" width="150" align="center">
            <template slot-scope="scope">
            <span>{{scope.row.verified_by}}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column fixed="right" align="center" label="Actions" >
            <template slot-scope="scope">
              <button class="btn rounded-0 btn btn-sm btn-primary font-weight-bold text-uppercase btn-primary mb-4"  v-if="scope.row.balance > 0" @click="clearInvoice(scope.row)">
                Clear Invoice
              </button>
            </template>
          </el-table-column> -->
         </el-table>
       </el-col>
     </el-row> 
      </el-tab-pane>
       <el-tab-pane label="Record Payments" name="record_payment"> 
          <el-row>
            <el-col>
              <el-table :data="record_payment" element-loading-text="Loading" stripe border fit>
                 <el-table-column type="index" width="50">
                    <template v-slot="{ $index }">
                            <span>{{ $index + pagination.per_page * ( pagination.current_page - 1 ) + 1 }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label='Payment Date' prop="payment_date" width="120">
                  <template slot-scope="scope">
                  {{scope.row.payment_date|formatDate('DD MMM YYYY')}} 
                  </template>
                </el-table-column>
                 <el-table-column label="Payment Method" prop="Payment Method" width="150" >
                    <template slot-scope="scope">                     
                          <span>{{scope.row.payment_method}}</span>                              
                    </template>
                  </el-table-column>
                   <el-table-column label="Payment Details" prop="Amount Received"  align="center">
                  <template slot-scope="scope">
                  <span v-if="scope.row.payment_method == 'Bank'">Bank A/C No : {{scope.row.payment_details}}</span>
                  <span v-if="scope.row.payment_method == 'Cheque'">Cheque No : {{scope.row.payment_details}}</span>
                  <span v-if="scope.row.payment_method == 'Email'">Email Transaction ID : {{scope.row.payment_details}}</span>
                  </template>
                </el-table-column>
                  <el-table-column label="Payment Amount" prop="Amount Received" width="150" align="center">
                    <template slot-scope="scope">
                    <span>$ {{parseFloat(scope.row.amount).toFixed(2)}}</span>
                    </template>
                </el-table-column>
                  <el-table-column fixed="right" align="center" label="Actions" width="80">
                    <template slot-scope="scope">
                      <el-dropdown @command="handleRecordActions($event,scope.row)" size="medium">
                        <i class="flaticon2-menu-1"></i>
                        <el-dropdown-menu slot="dropdown" class="action-dropdown">
                          <el-dropdown-item :command="1" ><i class="fa fa-eye"></i>View</el-dropdown-item>  
                        </el-dropdown-menu>
                      </el-dropdown>
                    </template>
                </el-table-column>
              </el-table>
               <div class="row">
              <div class="col-sm-12">
                <el-pagination v-if="!loading" style="margin-top:15px;"
                    background
                    layout="sizes, prev, pager, next"
                    @size-change="handleSizeChange"
                    @current-change="fetchData" 
                    :page-count="pagination.last_page" 
                    :page-sizes="[10, 25, 50, 100]"
                    :page-size="pagination.per_page"
                    :current-page.sync="pagination.current_page" 
                    :total="pagination.total_rows"
                    >
                </el-pagination>
              </div>
            </div>   
            </el-col>
          </el-row>
       </el-tab-pane>
         <el-tab-pane label="Record Payments Log" name="record_payment_log"> 
          <el-row>
            <el-col>
              <el-table :data="record_payment_log" element-loading-text="Loading" stripe border fit>
                 <el-table-column type="index" width="50">
                    <template v-slot="{ $index }">
                            <span>{{ $index + pagination.per_page * ( pagination.current_page - 1 ) + 1 }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label='Payment Date' prop="payment_date" width="120">
                  <template slot-scope="scope">
                  {{scope.row.created_at|formatDate('DD MMM YYYY')}} 
                  </template>
                </el-table-column>
                 <el-table-column label="Invoice #" prop="Payment Method" width="150" >
                    <template slot-scope="scope">                     
                          <span>{{scope.row.invoice.invoice_number}}</span>                              
                    </template>
                  </el-table-column>                 
                  <el-table-column label="Invoice Amount" prop="Amount Received" width="130" align="center">
                    <template slot-scope="scope">
                    <span>$ {{parseFloat(scope.row.invoice.total_amount).toFixed(2)}}</span>
                    </template>
                </el-table-column>
                  <el-table-column label="Term Closed Amount" prop="Amount Received" width="150" align="center">
                    <template slot-scope="scope">
                    <span>$ {{parseFloat(scope.row.term_closed_amount).toFixed(2)}}</span>
                    </template>
                </el-table-column>
                  <el-table-column label="Paid Amount" prop="Amount Received" width="130" align="center">
                    <template slot-scope="scope">
                    <span>$ {{parseFloat(scope.row.payment_amount).toFixed(2)}}</span>
                    </template>
                </el-table-column>
                 
              </el-table>
               <div class="row">
              <div class="col-sm-12">
                <el-pagination v-if="!loading" style="margin-top:15px;"
                    background
                    layout="sizes, prev, pager, next"
                    @size-change="handleSizeChange"
                    @current-change="fetchData" 
                    :page-count="pagination_log.last_page" 
                    :page-sizes="[10, 25, 50, 100]"
                    :page-size="pagination_log.per_page"
                    :current-page.sync="pagination_log.current_page" 
                    :total="pagination_log.total_rows"
                    >
                </el-pagination>
              </div>
            </div>   
            </el-col>
          </el-row>
       </el-tab-pane>
    </el-tabs>
   
    <v-dialog/>
  </div>
</template>

<script>
import {getCustomerInvoices, 
deleteStoreCredit,
getPayments,
getStoreCreditBalance,
getRecordPayment,
getCustomerMemo,
getCustomerRecordPayment,
getCustomerRecordPaymentLog} from "@/api/customer";
import {getOutstandingInvoice,getBalance} from "@/api/invoice";
import {globalEvents} from '@/utils/globalEvents';
import StoreCredit from '@/components/StoreCredit';
import ClearInvoice from '@/components/ClearInvoice';
import ClosedInvoice from '@/components/ClosedInvoice';
import RecoredPaymentLog from '@/components/RecordPayment/recordPaymentLog';
//import CardPayment from '@/components/Payment/creditcard';
import payNow from '@/components/Payment/payNow';
import {deleteInvoice} from "@/api/invoice";


export default {
  name: 'account-receivable',  
  data() {
    return {
      loading: false,
      invoices: [],
      store_credits: [],
      creditMemo : [],
      outstanding_invoices:[],
      payments:[],
      record_payment:[],
      activeAccName: 'invoices',
      credit_balance: 0,
      activeTab: 'out_invoice',
      pagination:{
          total_rows: 1,
          current_page: 1,
          last_page:null,
          per_page: 10,
        }, 
        pagination_log:{
          total_rows: 1,
          current_page: 1,
          last_page:null,
          per_page: 10,
        },     
      balance_amount:0,
      credit_memo: 0,
      recordPayment:[],
      record_payment_log:[],
      amount:0,
      record_total:0.00
    }
  },
  mounted() {
     globalEvents.$on('InvoicePaid', added => {
        this.fetchData(1);
      if (added) {
        this.fetchData(1);
      }
    })
    globalEvents.$on('invoiceAdded', added => {
        this.fetchData(1);
      if (added) {
        this.fetchData(1);
      }
    })
    
  },
  components:{
  },
  created() {
    this.fetchData(1)
    globalEvents.$on('storeCreditAdded', added => {    
      this.fetchData(1);
     // this.getCustomerMemo();          
      if (added) {        
        if(this.outstanding_invoices.length != 0){
          this.clearInvoice(added);
        }        
      }      
    })
    
  
  },
  methods: {
      handleClick(tab, event) {
      console.log(tab, event);
    },
    handleActions(evt, row) {
      if (evt === 1) {
          this.clearInvoice(row);
      } else if (evt === 2) {
          this.viewInvoicesByStoreCredit(row);
      } else if (evt === 3) {
          this.updateStoreCredit(row);
      } else if (evt === 4) {
        this.$bvModal.msgBoxConfirm('Are you sure you want to delete ?', {
            title: 'Please Confirm',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
        }).then(value => {
            if (value) {
                this.loading = true;
                deleteStoreCredit(row.id).then(response => {
                    if (response.data.status) {
                        this.fetchData()
                        this.$showResponse('success', response.data.message);
                    }
                    this.loading = false;
                })
            } else {
                return false;
            }
        })
      }
    },
     getDueAmount(row){     
       let due_amount = 0;
        if(row.term_paid_amount>0){         
            due_amount = parseFloat(row.term_paid_amount) + parseFloat(row.balance_amount);            
        }else{
          due_amount = parseFloat(row.balance_amount).toFixed(2);
        }
        return parseFloat(due_amount).toFixed(2);
     },
    handleRecordActions(evt, row){
      if (evt === 1) {
        console.log(row);
       this.$modal.show(RecoredPaymentLog, {recordPayment:row}, {
                    draggable: true,
                    transition: 'pop-out',
                    height: 'auto',
                    clickToClose: true,
                    adaptive: true,
                    width: '800px'
                });
      }
    },
    showAndHideColumn(column_name) {
        return this.$isColumnShow(column_name, this.checkedColumns);
    },
    handleSizeChange(per_page) {
        this.pagination.per_page = per_page;
        this.fetchData(1);
    },
     viewOrder(order_id) {
      this.$router.push({ path: "/order/view/" + order_id });
    },
     viewInvoice(invoice_id) {
      this.$router.push({ path: "/invoice/view/" + invoice_id });
    },
    handleCheckAllChange(val) {
        this.checkedColumns = [];
        if (val) {
            this.column_list.map((column, index) => {
                this.checkedColumns.push(column.value);
                console.log(index, this.checkedColumns);
            });
        }
        this.isIndeterminate = false;
    },
    fetchData(p) {
      this.loading = true
      //this.getStoreCredits();
      this.getStoreCreditBalance();
      this.getOutstandingInvoice();
      this.getBalance();
      this.getPayments();
      this.getCustomerMemo();
      this.getRecordPayment(p);
      this.getCustomerRecordPayment();
      this.getCustomerRecordPaymentLog();
    },
    getRecordPayment(p){
       let params = { page: p }
        if(this.filter){
          params.filter = this.filter
        }
        if(this.sort){
          params.sort = this.sort;
        }
          if(this.pagination.per_page){
          params.per_page = this.pagination.per_page;
        }       
         
     getRecordPayment(this.$route.params.customer_id,params).then(response => {   
          this.record_payment = response.data.data.data    
          this.pagination.current_page = response.data.data.current_page
          this.pagination.last_page = response.data.data.last_page
          this.pagination.total_rows = response.data.data.tota    
          this.loading = false   
      });
    
    },
     getCustomerRecordPaymentLog(p){
       let params = { page: p }
        if(this.filter){
          params.filter = this.filter
        }
        if(this.sort){
          params.sort = this.sort;
        }
          if(this.pagination_log.per_page){
          params.per_page = this.pagination_log.per_page;
        }       
         
   
     getCustomerRecordPaymentLog(this.$route.params.customer_id,params).then(response => {   
          this.record_payment_log = response.data.data.data    
          this.pagination_log.current_page = response.data.data.current_page
          this.pagination_log.last_page = response.data.data.last_page
          this.pagination_log.total_rows = response.data.data.tota    
          this.loading = false   
      });
    
    },
    getCustomerInvoices(){
      getCustomerInvoices(this.$route.params.customer_id).then(response => {
        this.invoices = response.data.data.data
        this.loading = false
      });
    },
    getCustomerRecordPayment(){
      getCustomerRecordPayment(this.$route.params.customer_id).then(response => {
        this.recordPayment = response.data.data
        this.amount = response.data.data.balance  
        this.record_total =  response.data.data.total_amount     
        this.loading = false
      });
    },
     getCustomerMemo(){
       this.loading = true
      getCustomerMemo(this.$route.params.customer_id).then(response => {      
      this.credit_memo = response.data.data.balance;
      this.creditMemo = response.data.data
        this.loading = false
      });
    },
     getPayments(){
      getPayments(this.$route.params.customer_id).then(response => {        
        this.payments = response.data.data
        //console.log(this.payments,'payment');
        this.loading = false
      });
    },
     getOutstandingInvoice(){
      getOutstandingInvoice(this.$route.params.customer_id).then(response => {        
        this.outstanding_invoices = response.data.data       
        this.loading = false
      });
    },
    // getStoreCredits(){
    //   getStoreCredits(this.$route.params.customer_id).then(response => {
    //     //this.store_credits = response.data.data.data
    //     this.loading = false
    //   });
    // },
    
    
    getStoreCreditBalance(){
      getStoreCreditBalance(this.$route.params.customer_id).then(response => {
        this.store_credits = response.data.data;
        let storecreditBalance = response.data.data.balance;
        this.credit_balance = storecreditBalance;
        this.loading = false
      });
    },
    getBalance(){
       getBalance(this.$route.params.customer_id).then(response => { 
            this.balance_amount = response.data.data   
        });
    },
    
    addStoreCredit() {
      let customerId = parseInt(this.$route.params.customer_id)
      this.$modal.show(StoreCredit, {
        customer_id: customerId
      }, {
        draggable: true,
        transition: 'pop-out',
        overflow: 'auto',
        height: 'auto',
        clickToClose: false,
        adaptive: true,
        width: '600'
      })
    },
    clearInvoice(creditMemo,payment_method){
     
       let amount = '';    
        amount = creditMemo        
        if(this.outstanding_invoices.length != 0){
        this.$modal.show(ClearInvoice, {
        store_credit: amount, payment_method : payment_method
          }, {
            draggable: true,
            transition: 'pop-out',
            overflow: 'auto',
            height: 'auto',
            clickToClose: false,
            adaptive: true,
            width: '1000'
          })
        }
     
    },
    updateStoreCredit(row) {
      let customerId = parseInt(this.$route.params.customer_id)
      this.$modal.show(StoreCredit, {
        customer_id: customerId,
        store_credit: row
      }, {
        draggable: true,
        transition: 'pop-out',
        overflow: 'auto',
        height: 'auto',
        clickToClose: false,
        adaptive: true,
        width: '600'
      })
    },
     getPaymentStatus(row){
        let date = new Date(row.payment_due_date);
        var periods = {
            month: 30 * 24 * 60 * 60 * 1000,
            week: 7 * 24 * 60 * 60 * 1000,
            day: 24 * 60 * 60 * 1000,
            hour: 60 * 60 * 1000,
            minute: 60 * 1000
        };
        var diff = Date.now() - date.getTime();
        var status = "";
        var status_msg = "";
        if(row.invoice_status === 'partially_paid'){
            status_msg = '<span class="partially-span el-tag el-tag--mini el-tag--light">Partially Paid</span>';
        }else if(row.invoice_status == 'unpaid' && Math.floor(diff / periods.day) > 0){
            if (diff > periods.month) {
                status = Math.floor(diff / periods.month) + " month ago";
            } else if (diff > periods.week) {
                status = Math.floor(diff / periods.week) + " week ago";
            } else if (diff > periods.day) {
                status = Math.floor(diff / periods.day) + " days ago";
            } /*else if (diff > periods.hour) {
                status = Math.floor(diff / periods.hour) + " hours ago";
            } else if (diff > periods.minute) {
                status = Math.floor(diff / periods.minute) + " minutes ago";
            }*/
            status_msg = '<span class="el-tag el-tag--mini el-tag--danger">Over Due</span><div>'+status+'</div>';
        }else if(row.invoice_status === 'paid'){
          status_msg = '<span class="paid-span el-tag el-tag--mini el-tag--success">Paid</span>';
          if(row.term_paid_amount > 0){
            status_msg = '<span class="paid-span el-tag el-tag--mini el-tag--success">Term</span>';
          }
        }else if(row.invoice_status === 'unpaid'){
            status_msg = '<span class="el-tag el-tag--mini el-tag--danger">Unpaid</span>';
        }

        return status_msg;
    },
    viewInvoicesByStoreCredit(row){
      this.$modal.show(ClosedInvoice, {
        store_credit: row
      }, {
        draggable: true,
        transition: 'pop-out',
        overflow: 'auto',
        height: 'auto',
        clickToClose: false,
        adaptive: true,
        width: '1000'
      })
    },
    handleActionsOutInvoice(evt, row) {
            if (evt === 1) {
               this.$router.push({
                path: '/invoice/view/' + row.order_id
            });
              
            } else if (evt === 2) {
                this.$bvModal.msgBoxConfirm('Are you sure you want to delete ?', {
                    title: 'Please Confirm',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'YES',
                    cancelTitle: 'NO',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                }).then(value => {
                    if (value) {
                        this.loading = true;
                        deleteInvoice(row.id).then(response => {
                            if (response.data.status) {
                                this.fetchData(1)
                                this.$showResponse('success', response.data.message);
                            }
                            this.loading = false;
                        })
                    } else {
                        return false;
                    }
                })
            }else if(evt === 4){
                this.$modal.show(payNow, {invoice:row}, {
                    draggable: true,
                    transition: 'pop-out',
                    height: 'auto',
                    clickToClose: true,
                    adaptive: true,
                    width: '450px'
                });
            }
        },
  }
};
</script>