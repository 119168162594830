<template>
  <div>
    <div slot="header" class="modal-header popup-header">
    <span class="modal-title-popup"><i class="flaticon-interface-9" aria-hidden="true"></i>Invoices</span>
      <i style="float: right; padding: 3px 0" class="model-close el-icon-close icon-pointer" @click.prevent="closeForm"></i>
    </div>
    <div class="card card-custom">
        <div class="card-body p-0">
            <!--begin: Employees-->
            <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
                <!--begin: Employees Body-->
                <div class="row justify-content-center ">
                    <div class="col-xl-12">
                      <el-row>
                        <el-col :span="11">
                          <div class="clear-invoice">
                            <span>{{payment_method}} Amount :  </span>
                            <span><strong>${{(store_credit.balance) ? store_credit.balance : 0.00}}</strong></span>
                          </div>
                        </el-col>
                        <el-col :span="11">
                          <div class="clear-invoice">
                            <span>{{payment_method}} Balance :  </span>
                            <span><strong>${{form.credit_balance ? form.credit_balance : 0.00}}</strong></span>
                          </div>
                        </el-col>
                      </el-row>
                      <el-row>
                        <el-col>
                          <div class="row">
                            <div class="col-sm-12">
                              <el-table :data="invoices" border element-loading-text="Loading" style="width: 100%" v-loading="loading">
                                <el-table-column label="" align="center" width="50">
                                  <template slot-scope="scope">
                                    <input type="checkbox" v-on:change="selectInvoice(scope.row, $event, scope.$index)"/>
                                  </template>
                                </el-table-column>
                                <el-table-column align="center" label='Status' prop="id"  width="150">
                                  <template slot-scope="scope">
                                      <div v-html="getPaymentStatus(scope.row)"></div>
                                  </template>
                                </el-table-column>                              
                                <el-table-column align="center" label='Invoice #' prop="id"  width="140">
                                  <template slot-scope="scope">
                                  <el-tag slot="reference" size="mini">{{(scope.row.invoice_number) ? scope.row.invoice_number : ""}}</el-tag>
                                  </template>
                                </el-table-column>
                                 <el-table-column align="center" label='Invoice Amount' prop="id"  width="120">
                                  <template slot-scope="scope">
                                  {{(scope.row.total_amount) ? '$'+scope.row.total_amount : '$0.00'}}
                                  </template>
                                </el-table-column>  
                                <el-table-column align="center" label='Amount Due' prop="id" width="140">
                                  <template slot-scope="scope">
                                  {{(scope.row.term_paid_amount) ? '$'+scope.row.term_paid_amount : '$0.00'}}
                                  </template>
                                </el-table-column>
                                <el-table-column align="center" label='Balance Due' prop="balance_amount" width="140">
                                   <template slot-scope="scope">
                                    $<span v-html="getDueAmount(scope.row)"></span>                                                                   
                                  </template>
                                </el-table-column>
                                <el-table-column align="center" label='Payment' prop="payment_amount" width="150" fixed="right">
                                  <template slot-scope="scope">
                                    <el-input-number v-model="scope.row.payment"  style="width:100%;" :min="0" 
                                     :max="parseFloat(getDueAmount(scope.row)).toFixed(2)" :controls="false" disabled>
                                    </el-input-number>
                                  </template>
                                </el-table-column>
                              </el-table>
                              <span class="form-err" v-if="form.errors.has('selected_invoice')" >{{form.errors.get('selected_invoice')}}</span>
                            </div>
                          </div>
                         </el-col>
                       </el-row>
                       <div class="d-flex justify-content-between border-top pt-5 flex-row-reverse">
                            <a class="text-white" @click="payCustomerInvoice()">
                                <div class="btn btn-primary btn-sm font-weight-bold text-uppercase">
                                    Pay
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <!--end: Employees Body-->
            </div>
        </div>
        <!--end: Employees-->
    </div>
    <v-dialog/>
  </div>
</template>

<script>
import {getCustomerInvoices} from "@/api/customer";
import Form from '@/core/services/api.form.services';
import {globalEvents} from '@/utils/globalEvents';
export default {
  name: 'clear-invoice',  
  props: {
    store_credit: {
      type: Object
    },
    payment_method: {
      type: String
    }
  },
  data() {
    return {
      loading: false,
      invoices: [],
      form : new Form({
        selected_invoice:[],
        credit_balance:0,
        store_credit_id: 0,
        payment_method: null, 
        customer_id:null,       
      })
    }
  },
  computed:{
    /*clickable(event) {
      console.log("Checked: ",event.target.checked);
      if(this.form.credit_balance == 0 && event.target && event.target.checked == false){
        return true;
      }else{
        return false;
      }
    }*/
  },
  mounted() {
  },
  components:{
  },
  created() {
    this.form.customer_id = this.$route.params.customer_id;
    this.form.credit_balance = this.store_credit.balance;
    this.form.store_credit_id = this.store_credit.id;
    this.form.payment_method = this.payment_method;
    this.fetchData(1)
  },
  methods: {
    closeForm() {
        this.$emit('close')
    },
    fetchData() {
      this.loading = true
      this.getCustomerInvoices();
    },
    PaymentChange(){

    },
    getCustomerInvoices(){
      getCustomerInvoices(this.$route.params.customer_id).then(response => {
        let invoices = response.data.data;
        invoices.map((invoice) => {         
          invoice['payment']=0; 
        })
        this.invoices = invoices
        this.loading = false
      });
    },
    addStoreCredit() {
    },
    getDueAmount(row){
     
       let due_amount = 0;
        if(row.term_paid_amount>0){         
            due_amount = parseFloat(row.term_paid_amount) + parseFloat(row.balance_amount);            
        }else{
          due_amount = parseFloat(row.balance_amount).toFixed(2);
        }
        return parseFloat(due_amount).toFixed(2);
     },
    handleActions(evt, row) {
      if (evt === 1) {
        console.log(row)
      } else if (evt === 2) {
        console.log(row)
      }
    },
    selectInvoice(row,event,index){      
      let credit_balance = parseFloat(this.form.credit_balance) - this.getDueAmount(row);       
      if(credit_balance < 0 && this.form.credit_balance != 0){
        if (event.target.checked) {
          this.$bvModal.msgBoxConfirm('Insufficient balance. Need to pay partial payment?', {
            title: 'Please Confirm',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          }).then(value => {
            
            if(!value){
              event.target.checked = false;
              this.setCreditBalance();
            }else{
              this.addInvoiceToSelected(row,event,index,'partial');
            }
          })
        }else{
          this.addInvoiceToSelected(row,event,index, 'full');
        }
      }else{
          if(this.form.credit_balance != 0){
            this.addInvoiceToSelected(row,event,index, 'full');
          }else{
            if(event.target.checked){
              this.$bvModal.msgBoxConfirm('Insufficient balance.',  {
                title: 'Please Confirm',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Ok',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
              }).then(value => {
                console.log(value)
                  event.target.checked = false;
              })
            }else{
              this.addInvoiceToSelected(row,event,index, 'full');
            }
          }
      }
    },
    addInvoiceToSelected(row, event, index, type){
      if (event.target.checked) {
        let amount = (type === 'partial') ? this.form.credit_balance : this.getDueAmount(row)
        
        this.invoices[index].payment = amount
        this.form.selected_invoice.push(this.invoices[index]);
      }else{
        this.removeInvoiceFromSelected(row.id);
        this.invoices[index].payment = 0;
      }
      this.setCreditBalance();
    },
    removeInvoiceFromSelected(invoice_id){
      this.form.selected_invoice.map((invoice, index) => {
        if(invoice.id === invoice_id){
          this.form.selected_invoice.splice(index,1);
        }
      });
    },
    setCreditBalance(){
      let selected_invoice_amount = 0;
      this.form.selected_invoice.map((invoice) => {        
        selected_invoice_amount = parseFloat(selected_invoice_amount) + parseFloat(invoice.payment);
      })      
      this.form.credit_balance = (this.store_credit.balance - selected_invoice_amount).toFixed(2);
    },
    getPaymentStatus(row){
        let date = new Date(row.payment_due_date);
        var periods = {
            month: 30 * 24 * 60 * 60 * 1000,
            week: 7 * 24 * 60 * 60 * 1000,
            day: 24 * 60 * 60 * 1000,
            hour: 60 * 60 * 1000,
            minute: 60 * 1000
        };
        var diff = Date.now() - date.getTime();
        var status = "";
        var status_msg = "";
        if(row.invoice_status === 'partially_paid'){
            status_msg = '<span class="partially-span el-tag el-tag--mini el-tag--light">Partially Paid</span>';
        }else if(row.invoice_status == 'unpaid' && Math.floor(diff / periods.day) > 0){
            if (diff > periods.month) {
                status = Math.floor(diff / periods.month) + " month ago";
            } else if (diff > periods.week) {
                status = Math.floor(diff / periods.week) + " week ago";
            } else if (diff > periods.day) {
                status = Math.floor(diff / periods.day) + " days ago";
            } /*else if (diff > periods.hour) {
                status = Math.floor(diff / periods.hour) + " hours ago";
            } else if (diff > periods.minute) {
                status = Math.floor(diff / periods.minute) + " minutes ago";
            }*/
            status_msg = '<span class="el-tag el-tag--mini el-tag--danger">Over Due</span><div>'+status+'</div>';
        }else if(row.invoice_status === 'paid'){
          status_msg = '<span class="paid-span el-tag el-tag--mini el-tag--success">Paid</span>';
          if(row.term_paid_amount > 0){
            status_msg = '<span class="paid-span el-tag el-tag--mini el-tag--success">Term</span>';
          }
            
        }else if(row.invoice_status === 'unpaid'){
            status_msg = '<span class="el-tag el-tag--mini el-tag--danger">Unpaid</span>';
        }

        return status_msg;
    },
    payCustomerInvoice(){    
      if(this.form.selected_invoice.length >0){
          this.$bvModal.msgBoxConfirm('Are you sure you want to proceed ?', {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
      }).then(value => {
          if (value) {
              this.loading = true;
              this.form.post('/invoice/pay/customer').then(response => {                 
                    globalEvents.$emit('InvoicePaid', response)
                    this.$emit('close')
                this.loading = false;
              }).catch(err => {
                console.log(err);
                this.loading = false;
              });
          } else {
              return false;
          }
      })
      }else{
         const h = this.$createElement;
            this.$notify({
              message: h(
                "i",
                {
                  style: "color: red",
                },
                "Please select invoice(s)"
              ),
              offset: 200,
              type: "warning",
            });
      } 
    }
  }
};
</script>