<!-- Settings -->
<template>
  <div class="card card-custom" v-loading="loading">
    <div class="card-body p-0">
      <div class="row">
        <div class="col-sm-12">
          <div class="profile-user-box card-box bg-custom">
            <div class="row">
              <div class="col-sm-8">
                <div class="profile-section d-flex">
                  <span
                    class="index-image employee-image"
                    v-loadimage="view.profile_image"
                  ></span>
                  <div class="media-body">
                    <h4 class="mt-1 mb-3">
                      {{ view.first_name }} {{ view.last_name &&  view.last_name !=null ?  view.last_name : ''}}
                      <!-- <span class="user-active" v-if="view.active === 'true'"><i class="flaticon2-check-mark"></i>Active</span>
                              <span class="user-inactive" v-else><i class="flaticon2-cancel-music"></i>In Active</span> -->
                    </h4>
                    <div class="d-flex flex-wrap justify-content-between mt-1">
                      <div class="d-flex flex-column flex-grow-1 pr-8">
                        <div class="d-flex flex-wrap mb-4" v-if="view.id">
                          <h6
                            class="
                              text-dark
                              font-weight-bold
                              mr-lg-8 mr-5
                              mb-lg-0 mb-1
                            "
                          >
                            <i class="flaticon2-user mr-2 font-size-lg"></i>Customer Id : {{ view.customer_id }}
                          </h6>
                        </div>
                        <div
                          class="d-flex flex-wrap mb-4"
                          v-if="view.email_address"
                        >
                          <span
                            class="
                              text-dark
                              font-weight-bold
                              mr-lg-8 mr-5
                              mb-lg-0 mb-1
                            "
                          >
                            <i
                              class="
                                flaticon-email-black-circular-button
                                mr-2
                                font-size-lg
                              "
                            ></i>
                            <a :href="'mailto:' + view.email_address">{{
                              view.email_address
                            }}</a>
                          </span>
                        </div>
                        <div
                          class="d-flex flex-wrap mb-4"
                          v-if="view.customer_mobile || view.customer_phone"
                        >
                          <span
                            class="
                              text-dark
                              font-weight-bold
                              mr-lg-8 mr-5
                              mb-lg-0 mb-1
                            "
                          >
                            <i class="flaticon2-phone mr-2 font-size-lg"></i>
                            <a
                              :href="'tel:' + view.customer_mobile"
                              v-if="view.customer_mobile"
                              >+1 {{ view.customer_mobile }}</a
                            >
                            <a
                              :href="'tel:' + view.customer_phone"
                              v-if="view.customer_phone"
                              > {{view.customer_mobile ? ',+1 '+view.customer_phone : '' }}</a
                            >
                          </span>
                        </div>
                        <div
                          class="d-flex flex-wrap mb-4"
                          v-if="view.customer_fax"
                        >
                          <span
                            class="
                              text-dark
                              font-weight-bold
                              mr-lg-8 mr-5
                              mb-lg-0 mb-1
                            "
                          >
                            <i class="flaticon2-fax mr-2 font-size-lg"></i>
                            {{ view.customer_fax }}
                          </span>
                        </div>
                        <div class="d-flex flex-wrap mb-4">
                          <span
                            class="
                              text-dark
                              font-weight-bold
                              mr-lg-8 mr-5
                              mb-lg-0
                            "
                          >
                            <i
                              class="flaticon-map-location mr-2 font-size-lg"
                            ></i>
                            
                            <span v-if="view.street">{{ view.street }}, </span> 
                             <span v-if="view.unit_no">Unit No : {{ view.unit_no }}, </span> 
                            {{ view.city ? view.city.city_name + ", " : "" }}
                            {{ view.state ? view.state.state_name : "" }}
                            {{ view.post_code ? ' - '+view.post_code : '' }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="d-flex flex-lg-fill mb-2">
                  <span class="mr-4"
                    ><i
                      class="
                        flaticon-pie-chart
                        display-4
                        text-muted
                        font-weight-bold
                      "
                    ></i
                  ></span>
                  <div class="d-flex flex-column text-dark-75">
                    <span class="font-weight-bolder font-size-md"
                      >{{ new Date().getFullYear() }} Sales</span
                    >
                    <span class="font-weight-bolder font-size-h5"
                      ><span class="text-dark-50 font-weight-bold">$</span
                      >{{ sales_year }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="text-right">
                  <b-button
                    class="
                      rounded-0
                      btn btn-sm btn-primary
                      font-weight-bold
                      text-uppercase
                    "
                    variant="primary"
                    @click="editCustomer(view.id)"
                    ><i class="fa fa-pen"></i>Edit
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-sm-5">
          <div class="row">
            <div class="col-sm-4">
              <div class="profile-attr">
                <label><i class="flaticon2-world"></i>Store</label>
                <p>
                  {{
                    view.website_list != null
                      ? view.website_name : "-"
                  }}
                </p>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="profile-attr">
                <label><i class="flaticon-buildings"></i>Company</label>
                <p>{{ view.company_name ? view.company_name : "-" }}</p>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="profile-attr">
                <label
                  ><i class="flaticon-calendar-with-a-clock-time-tools"></i
                  >Created Date</label
                >
                <p>{{ view.created_at | formatDate("DD MMM YYYY") }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-7">
          <div class="row">
            <div class="col-sm-3">
              <div class="profile-attr">
                <label
                  ><i class="flaticon-email"></i>Part of Mailing List</label
                >
                <p>{{ view.mailing_list === "Yes" ? "Yes" : "No" }}</p>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="profile-attr">
                <label><i class="flaticon-user"></i>Category</label>
                <p>
                  {{ view.customer_type ? view.customer_type.type_name : "" }}
                </p>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="profile-attr">
                <label><i class="flaticon-user"></i>Job Category</label>
                <p>{{ view.job_category ? view.job_category : "" }}</p>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="profile-attr">
                <label
                  ><i class="flaticon-shopping-basket"></i>Outside Sales</label
                >
                <p>{{ view.outside_sales ? view.outside_sales : "" }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="row mt-10">
        <div class="col-sm-12">
          <template>
            <el-tabs
              class="root-tab"
              type="card"
              v-model="activeTab"
              @tab-click="handleClick"
            >
               <el-tab-pane label="Orders" name="Orders">
                <template>
                  <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="Orders" name="order">
                      <el-table
                        :data="oredr_details"
                        element-loading-text="Loading"
                        stripe
                        border
                        fit
                      >
                        <el-table-column
                          fixed
                          align="center"
                          label="Order Number"
                          prop="order_number"
                          sortable
                          width="150"
                        >
                          <template slot-scope="scope">
                            <el-tag slot="reference" size="mini">
                              <a href="#" @click.prevent="viewOrder(scope.row.id)">
                                {{scope.row.order_number ? scope.row.order_number: ""}}
                              </a>
                            </el-tag>
                          </template>
                        </el-table-column>
                         <el-table-column
                          label="Order Date"
                          prop="delivery_date"                          
                          width="120"
                          align="center"
                        >
                          <template slot-scope="scope">
                            <span>{{ format_date(scope.row.created_at) }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column
                          align="center"
                          label="Invoice #"
                          prop="invoice_number"
                          sortable
                          width="120"
                        >
                          <template slot-scope="scope">
                            <span>{{ scope.row.invoice_number }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column label=" Order Status" width="150" prop = "status" align="center" >
                            <template slot-scope="scope">
                              <span v-if="scope.row.order_status == 'waiting_payment'" class="red font-weight-bold">Waiting for Payment</span>
                              <span v-if="scope.row.order_status == 'yet_verified'" class="red font-weight-bold">Order Preparation</span>
                              <span v-if="scope.row.order_status == 'pending'" class="gray font-weight-bold">Pending</span>
                              <span v-if="scope.row.order_status == 'processing'" class="active">Processing</span>
                              <span v-if="scope.row.order_status == 'pickup'" class="active">Ready to Pick Up</span>
                              <span v-if="scope.row.order_status == 'on-hold'" class="gray">On Hold</span>
                              <span v-if="scope.row.order_status == 'completed'" class="active">Completed</span>
                              <span v-if="scope.row.order_status == 'cancelled'" class="red">Cancelled</span>
                              <span v-if="scope.row.order_status == 'refund'" class="red">Refunded</span>
                              <span v-if="scope.row.order_status == 'backorder'" class="red">Backorder</span>
                              <span v-if="scope.row.order_status == 'partially_cancel'" class="red">Partially Cancel</span>
                              <span v-if="scope.row.order_status == 'failed'" class="red">Failed</span>
                              <span v-if="scope.row.order_status == 'waiting_dispatch'" class="yellow">Waiting Dispatch</span>
                              <span v-if="scope.row.order_status == 'dispatched'" class="yellow">In Transit</span>
                            </template>
                          </el-table-column> 
                          <el-table-column
                          label="Payment Status"
                          prop="order_status"                          
                          width="130"
                          align="center"
                          >
                          <template slot-scope="scope">
                            <div
                              v-html="getOrderPaymentStatus(scope.row)"
                            ></div>
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Ship Via"
                          prop="ship_via"                          
                          width="120"
                        >
                          <template slot-scope="scope">
                            <span>{{ scope.row.ship_via }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Shiping Method"
                          prop="shiping_method"                          
                          width="150"
                        >
                          <template slot-scope="scope">
                            <span>{{ scope.row.shiping_method }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Amount"
                          prop="total"
                          sortable
                          width="100"
                          align="center"
                        >
                          <template slot-scope="scope">
                            <span>${{ scope.row.total }}</span>
                          </template>
                        </el-table-column>
                         <el-table-column
                          align="center"
                          label="Balance Due"
                          prop="balance_amount"
                          sortable
                          width="140"
                        >
                          <template slot-scope="scope">
                            {{
                              scope.row.invoice
                                ? "$" + scope.row.invoice.balance_amount
                                : "$0.00"
                            }}
                          </template>
                        </el-table-column>
                       
                        <el-table-column
                          label="Delivery Date"
                          prop="delivery_date"                          
                          width="120"
                          align="center"
                        >
                          <template slot-scope="scope">
                            <span>{{
                              format_date(scope.row.delivery_date)
                            }}</span>
                          </template>
                        </el-table-column>                         
                        <el-table-column
                          fixed="right"
                          align="center"
                          label="Actions"
                          width="80"
                        >
                          <template slot-scope="scope">
                            <el-dropdown
                              @command="handleOrderView($event, scope.row)"
                              size="medium"
                            >
                              <i class="flaticon2-menu-1"></i>
                              <el-dropdown-menu
                                slot="dropdown"
                                class="action-dropdown"
                              >
                                <el-dropdown-item :command="1"
                                  ><i class="fa fa-eye"></i
                                  >View</el-dropdown-item
                                >
                                <el-dropdown-item :command="2" 
                                v-if="scope.row.payment_status != 'paid' || scope.row.order_status == 'cancelled'">
                                <i class="fas fa-money-bill-wave"></i>Pay Now</el-dropdown-item>                    
                                
                              </el-dropdown-menu>
                            </el-dropdown>
                          </template>
                        </el-table-column>
                      </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="Invoice" name="invoice">
                      <el-table
                        :data="invoice_list"
                        border
                        element-loading-text="Loading"
                        style="width: 100%"
                        v-loading="loading"
                      >
                        <el-table-column
                          align="center"
                          label="Invoice #"
                          prop="id"
                          sortable
                          width="140"
                        >
                          <template slot-scope="scope">
                            <el-tag slot="reference" size="mini"
                              ><a
                                href="#"
                                @click.prevent="viewInvoice(scope.row.order_id)"
                                >{{
                                  scope.row.invoice_number
                                    ? scope.row.invoice_number
                                    : ""
                                }}</a
                              ></el-tag
                            >
                          </template>
                        </el-table-column>
                         <el-table-column
                          align="center"
                          label="Status"
                          prop="id"
                          sortable
                          width="140"
                        >
                          <template slot-scope="scope">
                            <div v-html="getPaymentStatus(scope.row)"></div>
                          </template>
                        </el-table-column>
                         <el-table-column
                          align="center"
                          label="Term Status"
                          prop="id"
                          sortable
                          width="140"
                        >
                          <template slot-scope="scope">
                            <div v-html="getTermStatus(scope.row)"></div>
                          </template>
                        </el-table-column>
                        <el-table-column
                          align="center"
                          label="Order #"
                          prop="id"
                          sortable
                          width="140"
                        >
                          <template slot-scope="scope">
                            <el-tag slot="reference" size="mini"
                              ><a
                                href="#"
                                @click.prevent="viewOrder(scope.row.order.id)"
                                >{{
                                  scope.row.order.order_number
                                    ? scope.row.order.order_number
                                    : ""
                                }}</a
                              ></el-tag
                            >                           
                          </template>
                        </el-table-column>                       
                        <el-table-column
                          align="center"
                          label="Amount Due"
                          prop="id"
                          sortable
                          width="140"
                        >
                          <template slot-scope="scope">
                            {{
                              scope.row.order.total
                                ? "$" + scope.row.order.total
                                : "$0.00"
                            }}
                          </template>
                        </el-table-column>
                        <el-table-column
                          align="center"
                          label="Balance Due"
                          prop="balance_amount"
                          sortable
                          width="140"
                        >
                          <template slot-scope="scope">
                            {{
                              scope.row.balance_amount
                                ? "$" + scope.row.balance_amount
                                : "$0.00"
                            }}
                          </template>
                        </el-table-column>
                       
                        <el-table-column
                          align="center"
                          label="Due Date"
                          prop="id"                          
                          width="140"
                        >
                          <template slot-scope="scope">
                            {{ scope.row.payment_due_date | formatDate }}
                          </template>
                        </el-table-column>
                         <el-table-column
                          align="center"
                          label="Created Date"
                          prop="id"                          
                          width="140"
                        >
                          <template slot-scope="scope">
                            {{ scope.row.created | formatDate }}
                          </template>
                        </el-table-column>
                        <!-- <el-table-column align="center" label='Payment' prop="id" sortable width="140">
                            <template slot-scope="scope">
                                <span class="btn btn-sm btn-primary btn-rounded" @click="recordPayment(scope.row.id)">Record Payment</span>
                            </template>
                          </el-table-column> -->
                        <el-table-column
                          fixed="right"
                          align="center"
                          label="Actions"
                          width="80"
                        >
                          <template slot-scope="scope">
                            <el-dropdown
                              @command="handleActionsInvoice($event, scope.row)"
                              size="medium"
                            >
                              <i class="flaticon2-menu-1"></i>
                              <el-dropdown-menu
                                slot="dropdown"
                                class="action-dropdown"
                              >
                                <!-- <el-dropdown-item :command="3" ><i class="fa fa-credit-card"></i>Record Payment</el-dropdown-item>
                                    <el-dropdown-item :command="4" ><i class="fa fa-credit-card"></i>Card Payment</el-dropdown-item> -->
                                <el-dropdown-item :command="5" v-if="scope.row.invoice_status !='paid'"
                                  ><i class="fa fa-credit-card"></i>Pay
                                  Now</el-dropdown-item
                                >
                                <el-dropdown-item :command="1"
                                  ><i class="fa fa-eye"></i
                                  >View</el-dropdown-item
                                >                               
                              </el-dropdown-menu>
                            </el-dropdown>
                          </template>
                        </el-table-column>
                      </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="Complete Order" name="complete">
                      <el-table :data="complete_order" border element-loading-text="Loading" style="width: 100%" v-loading="loading">
                      <el-table-column label="Delivery #" sortable width="120" >
                        <template slot-scope="scope">
                          <el-tag slot="reference" size="mini"><a href="#" @click.prevent="viewDelivery(scope.row.id)">{{scope.row.id}}</a></el-tag>                
                        </template>
                      </el-table-column>              
                      <el-table-column label="Order #" sortable width="120" >
                        <template slot-scope="scope">
                          <el-tag slot="reference" size="mini"><a href="#" @click.prevent="viewOrder(scope.row.order.id)">{{(scope.row.order) ? scope.row.order.order_number : ""}}</a></el-tag>                
                        </template>
                      </el-table-column>             
                      <el-table-column label="Customer Name" prop="customer_name" sortable min-width="200" >
                        <template slot-scope="scope">
                        {{scope.row.customer?scope.row.customer.first_name+' '+(scope.row.customer.last_name==null?'':scope.row.customer.last_name):""}}
                        </template>
                      </el-table-column>              
                      
                      <el-table-column label="Delivery Date" prop="delivery_date" sortable width="120" >
                        <template slot-scope="scope">
                        {{scope.row.order.delivery_date|formatDate('DD MMM YYYY')}} 
                        </template>
                      </el-table-column> 
                      <!-- <el-table-column label="Delivery Document" prop="customer_name" sortable min-width="200" >
                        <template slot-scope="scope">
                        {{scope.row.delivery_document}}
                        </template>
                      </el-table-column> -->             
                      <el-table-column label="PackingSlip Status" prop="customer_name" sortable min-width="200" >
                        <template slot-scope="scope">
                        {{scope.row.packing_status}}
                        </template>
                      </el-table-column>    
                      <el-table-column label=" Order Status" sortable width="150" prop = "status" align="center" >
                        <template slot-scope="scope">
                          <span v-if="scope.row.order_status == 'waiting_payment'" class="red font-weight-bold">Waiting for Payment</span>
                          <span v-if="scope.row.order_status == 'yet_verified'" class="red font-weight-bold">Order Preparation</span>
                          <span v-if="scope.row.order_status == 'pending'" class="gray font-weight-bold">Pending</span>
                          <span v-if="scope.row.order_status == 'processing'" class="active">Processing</span>
                          <span v-if="scope.row.order_status == 'pickup'" class="active">Ready to Pick Up</span>
                          <span v-if="scope.row.order_status == 'on-hold'" class="gray">On Hold</span>
                          <span v-if="scope.row.order_status == 'completed'" class="active">Completed</span>
                          <span v-if="scope.row.order_status == 'cancelled'" class="red">Cancelled</span>
                          <span v-if="scope.row.order_status == 'refund'" class="red">Refunded</span>
                          <span v-if="scope.row.order_status == 'backorder'" class="red">Backorder</span>
                          <span v-if="scope.row.order_status == 'partially_cancel'" class="red">Partially Cancel</span>
                          <span v-if="scope.row.order_status == 'failed'" class="red">Failed</span>
                          <span v-if="scope.row.order_status == 'waiting_dispatch'" class="yellow">Waiting Dispatch</span>
                          <span v-if="scope.row.order_status == 'dispatched'" class="yellow">In Transit</span>
                            </template>
                      </el-table-column> 
                      <el-table-column label="Payment Status" width="130">
                        <template slot-scope="scope">
                            <div v-html="getPaymentStatus1(scope.row)"></div>
                        </template>
                      </el-table-column> 
                      <el-table-column label="Delivery Verified By" prop="customer_name" sortable min-width="200" >
                        <template slot-scope="scope">
                        {{scope.row.edit_person_name}}
                        </template>
                      </el-table-column>           
                        <el-table-column fixed="right" align="center" label="Actions" width="80">
                        <template slot-scope="scope">
                          <el-dropdown @command="handleCompleteOrder($event,scope.row)" size="medium">
                            <i class="flaticon2-menu-1"></i>
                            <el-dropdown-menu slot="dropdown" class="action-dropdown">
                              <el-dropdown-item :command="1" ><i class="fa fa-eye"></i>View</el-dropdown-item>                      
                            </el-dropdown-menu>
                          </el-dropdown>
                        </template>
                      </el-table-column>
                    </el-table>                   
                    </el-tab-pane>
                      
                    <el-tab-pane label="Pending Orders" name="pending">
                      <el-table
                        :data="pending_order"
                        element-loading-text="Loading"
                        stripe
                        border
                        fit
                      >
                        <el-table-column
                          
                          align="center"
                          label="Order Number"
                          prop="order_number"
                          sortable
                          width="150"
                        >
                          <template slot-scope="scope">
                            <el-tag slot="reference" size="mini"
                              ><a
                                href="#"
                                @click.prevent="viewOrder(scope.row.id)"
                                >{{
                                  scope.row.order_number
                                    ? scope.row.order_number
                                    : ""
                                }}</a
                              ></el-tag
                            >
                          </template>
                        </el-table-column>
                        <el-table-column
                          align="center"
                          label="Invoice #"
                          prop="invoice_number"
                          sortable
                          width="120"
                        >
                          <template slot-scope="scope">
                            <span>{{ scope.row.invoice_number }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Ship Via"
                          prop="ship_via"
                          sortable
                          width="120"
                        >
                          <template slot-scope="scope">
                            <span>{{ scope.row.ship_via }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Shiping Method"
                          prop="shiping_method"
                          sortable
                          :min-width="150"
                        >
                          <template slot-scope="scope">
                            <span>{{ scope.row.shiping_method }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Amount"
                          prop="total"
                          sortable
                          width="100"
                          align="center"
                        >
                          <template slot-scope="scope">
                            <span>${{ scope.row.total }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Order Date"
                          prop="delivery_date"
                          sortable
                          width="120"
                          align="center"
                        >
                          <template slot-scope="scope">
                            <span>{{ format_date(scope.row.created_at) }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Delivery Date"
                          prop="delivery_date"
                          sortable
                          width="120"
                          align="center"
                        >
                          <template slot-scope="scope">
                            <span>{{
                              format_date(scope.row.delivery_date)
                            }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column
                         
                          align="center"
                          label="Actions"
                          width="80"
                        >
                          <template slot-scope="scope">
                            <el-dropdown
                              @command="handleView($event, scope.row)"
                              size="medium"
                            >
                              <i class="flaticon2-menu-1"></i>
                              <el-dropdown-menu
                                slot="dropdown"
                                class="action-dropdown"
                              >
                                <el-dropdown-item :command="1"
                                  ><i class="fa fa-eye"
                                    >View</i
                                  ></el-dropdown-item
                                >
                                <!-- <el-dropdown-item :command="2" ><i class="fa fa-credit-card"></i>Pay Now</el-dropdown-item>  -->
                              </el-dropdown-menu>
                            </el-dropdown>
                          </template>
                        </el-table-column>
                      </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="Cancelled Orders" name="cancel">
                      <el-table
                        :data="cancel_order"
                        element-loading-text="Loading"
                        stripe
                        border
                        fit
                      >
                        <el-table-column
                          fixed
                          align="center"
                          label="Cancel ID"
                          prop="order_number"
                          width="150"
                        >
                          <template slot-scope="scope">
                            <el-tag slot="reference" size="mini"
                              ><a
                                href="#"
                                @click.prevent="viewCancelOrder(scope.row.id)"
                                >{{ scope.row.cancel_order_number ? scope.row.cancel_order_number : "" }}</a
                              ></el-tag
                            >
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Order Number"
                          prop="order_number"
                          width="150"
                          align="center"
                        >
                          <template slot-scope="scope">
                            <span>${{ scope.row.order.order_number }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Return Amount"
                          prop="total"
                          width="150"
                          align="center"
                        >
                          <template slot-scope="scope">
                            <span>${{ scope.row.cancel_amount }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Return Type"
                          prop="total"
                          align="center"
                        >
                          <template slot-scope="scope">
                            <span
                              v-if="
                                scope.row.return_amount_type == 'store_credit'
                              "
                              >Store Credit</span
                            >
                            <span
                              v-if="
                                scope.row.return_amount_type == 'refund_online'
                              "
                              >Refund Online</span
                            >
                            <span
                              v-if="
                                scope.row.return_amount_type == 'refund_offline'
                              "
                              >Refund Offline</span
                            >
                             <span
                              v-if="
                                scope.row.return_amount_type == 'credit_memo'
                              "
                              >Credit Memo</span
                            >
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Cancel Reason"
                          prop="total"
                          align="center"
                        >
                          <template slot-scope="scope">
                            <span>{{ scope.row.cancel_reason }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Cancel Date"
                          prop="delivery_date"
                          width="150"
                          align="center"
                        >
                          <template slot-scope="scope">
                            <span>{{ format_date(scope.row.created_at) }}</span>
                          </template>
                        </el-table-column>
                      </el-table>
                    </el-tab-pane>
                       <el-tab-pane label="Refund Orders" name="refund">
                      <el-table
                        :data="refund_order"
                        element-loading-text="Loading"
                        stripe
                        border
                        fit
                      >
                        <el-table-column
                          fixed
                          align="center"
                          label="Refund ID"
                          prop="order_number"
                          width="150"
                        >
                          <template slot-scope="scope">
                            <el-tag slot="reference" size="mini"
                              ><a
                                href="#"
                                @click.prevent="viewCancelOrder(scope.row.id)"
                                >{{ scope.row.cancel_order_number ? scope.row.cancel_order_number : "" }}</a
                              ></el-tag
                            >
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Order Number"
                          prop="order_number"
                          width="150"
                          align="center"
                        >
                          <template slot-scope="scope">
                            <span>${{ scope.row.order.order_number }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Return Amount"
                          prop="total"
                          width="150"
                          align="center"
                        >
                          <template slot-scope="scope">
                            <span>${{ scope.row.cancel_amount }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Return Type"
                          prop="total"
                          align="center"
                        >
                          <template slot-scope="scope">
                            <span
                              v-if="
                                scope.row.return_amount_type == 'store_credit'
                              "
                              >Store Credit</span
                            >
                            <span
                              v-if="
                                scope.row.return_amount_type == 'refund_online'
                              "
                              >Refund Online</span
                            >
                            <span
                              v-if="
                                scope.row.return_amount_type == 'refund_offline'
                              "
                              >Refund Offline</span
                            >
                             <span
                              v-if="
                                scope.row.return_amount_type == 'credit_memo'
                              "
                              >Credit Memo</span
                            >
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Refund Reason"
                          prop="total"
                          align="center"
                        >
                          <template slot-scope="scope">
                            <span>{{ scope.row.cancel_reason }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Refund Date"
                          prop="delivery_date"
                          width="150"
                          align="center"
                        >
                          <template slot-scope="scope">
                            <span>{{ format_date(scope.row.created_at) }}</span>
                          </template>
                        </el-table-column>
                      </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="Back Orders" name="backorder">
                      <el-table
                        :data="back_list"
                        element-loading-text="Loading"
                        stripe
                        border
                        fit
                      >
                        <el-table-column
                          label="Back Order #"
                          sortable
                          width="150"
                        >
                          <template slot-scope="scope">
                            <el-tag slot="reference" size="mini"
                              ><a
                                href="#"
                                @click.prevent="viewBackorder(scope.row.id)"
                                >{{
                                  scope.row.order_number
                                    ? scope.row.order_number
                                    : ""
                                }}</a
                              ></el-tag
                            >
                          </template>
                        </el-table-column>
                        <el-table-column label="Order #" sortable width="120">
                          <template slot-scope="scope">
                            <el-tag slot="reference" size="mini"
                              ><a
                                href="#"
                                @click.prevent="viewOrder(scope.row.order.id)"
                                >{{
                                  scope.row.order
                                    ? scope.row.order.order_number
                                    : ""
                                }}</a
                              ></el-tag
                            >
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Order Created"
                          prop="customer_name"
                          sortable
                          min-width="200"
                        >
                          <template slot-scope="scope">
                            {{
                              scope.row.back_order_created_by
                                ? scope.row.back_order_created_by
                                : ""
                            }}
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Created Date"
                          prop="delivery_date"
                          sortable
                          width="120"
                        >
                          <template slot-scope="scope" v-if="scope.row.created_at">
                            {{
                              scope.row.created_at | formatDate("DD MMM YYYY")
                            }}
                          </template>
                        </el-table-column>
                        <el-table-column
                          label=" Order Status"
                          sortable
                          width="150"
                          prop="status"
                          align="center"
                        >
                          <template slot-scope="scope">
                            <span
                              v-if="scope.row.order_status == 'completed'"
                              class="active"
                              >Completed</span
                            >
                            <span
                              v-if="scope.row.order_status == 'processing'"
                              class="active"
                              >Processing</span
                            >
                            <span
                              v-if="
                                scope.row.order_status == 'partially processed'
                              "
                              class="active"
                              >Partially Processed
                            </span>
                            <span
                              v-if="scope.row.order_status == 'backorder'"
                              class="red"
                              >BackOrder</span
                            >
                            <span
                              v-if="scope.row.order_status == 'cancel'"
                              class="red"
                              >Cancel Order</span
                            >
                          </template>
                        </el-table-column>
                      </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="Packing Slip" name="packingslip">
                      <el-table
                        :data="packing_list"
                        element-loading-text="Loading"
                        stripe
                        border
                        fit
                      >
                        <el-table-column
                          fixed
                          align="center"
                          label="Packing #"
                          prop="packing_id"
                          sortable
                        >
                          <template slot-scope="scope">
                            <el-tag slot="reference" size="mini"
                              ><a
                                href="#"
                                @click.prevent="
                                  viewPacking(scope.row.packing_id)
                                "
                                >{{
                                  scope.row.packing_no
                                    ? scope.row.packing_no
                                    : ""
                                }}</a
                              ></el-tag
                            >
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Order Number"
                          align="center"
                          prop="order_number"
                          sortable
                        >
                          <template slot-scope="scope">
                            <span>{{ scope.row.order.order_number }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Total Weight"
                          align="center"
                          prop="total_weight"
                          sortable
                        >
                          <template slot-scope="scope">
                            <span>{{ scope.row.total_weight }} Kg</span>
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Created Date"
                          align="center"
                          prop="created_at"
                          sortable
                        >
                          <template slot-scope="scope" v-if="scope.row.created_at">
                            <span>{{ format_date(scope.row.created_at) }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Ship Date"
                          align="center"
                          prop="ship_date"
                          sortable
                        >
                          <template slot-scope="scope">
                            <span>{{ format_date(scope.row.ship_date) }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column
                          fixed="right"
                          align="center"
                          label="Actions"
                          width="80"
                        >
                          <template slot-scope="scope">
                            <el-dropdown
                              @command="handlePacking($event, scope.row)"
                              size="medium"
                            >
                              <i class="flaticon2-menu-1"></i>
                              <el-dropdown-menu
                                slot="dropdown"
                                class="action-dropdown"
                              >
                                <el-dropdown-item :command="1"
                                  >View</el-dropdown-item
                                >
                              </el-dropdown-menu>
                            </el-dropdown>
                          </template>
                        </el-table-column>
                      </el-table>
                    </el-tab-pane> 
                     <el-tab-pane label="Return Orders" name="return">
                      <el-table
                        :data="return_order"
                        element-loading-text="Loading"
                        stripe
                        border
                        fit
                      >
                       <el-table-column
                          
                          align="center"
                          label="Return Order #"
                          prop="return_order"
                          sortable
                          width="150"
                        >
                          <template slot-scope="scope">
                            <el-tag slot="reference" size="mini"
                              ><a
                                href="#"
                                @click.prevent="viewReturnorder(scope.row.id)"
                                >RO-{{
                                  scope.row.id
                                    ? scope.row.id
                                    : ""
                                }}</a
                              ></el-tag
                            >
                          </template>
                        </el-table-column>
                        <el-table-column
                          
                          align="center"
                          label="Order Number"
                          prop="order_number"
                          sortable
                          width="150"
                        >
                          <template slot-scope="scope">
                            <el-tag slot="reference" size="mini"
                              ><a
                                href="#"
                                @click.prevent="viewOrder(scope.row.order_details.id)"
                                >{{
                                  scope.row.order_details.order_number
                                    ? scope.row.order_details.order_number
                                    : ""
                                }}</a
                              ></el-tag
                            >
                          </template>
                        </el-table-column>
                        <el-table-column
                          align="center"
                          label="Invoice #"
                          prop="invoice_number"
                          sortable
                          width="120"
                        >
                          <template slot-scope="scope">
                            <span>{{ scope.row.order_details.invoice_number }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Ship Via"
                          prop="ship_via"
                          sortable
                          width="120"
                        >
                          <template slot-scope="scope">
                            <span>{{ scope.row.order_details.ship_via }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Shiping Method"
                          prop="shiping_method"
                          sortable
                          width="150"
                        >
                          <template slot-scope="scope">
                            <span>{{ scope.row.order_details.shiping_method }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Amount"
                          prop="total"
                          sortable
                          width="100"
                          align="center"
                        >
                          <template slot-scope="scope">
                            <span>${{ scope.row.total }}</span>
                          </template>
                        </el-table-column>
                         <el-table-column
                          label="Return Type"
                          prop="return_amount"
                          sortable
                          width="150"
                          align="center"
                        >
                          <template slot-scope="scope">
                            <span>{{ scope.row.return_amount_type }}</span>
                          </template>
                        </el-table-column>
                         <el-table-column
                          label="Reason"
                          prop="total"
                          sortable
                          :min-width="150"
                          align="center"
                        >
                          <template slot-scope="scope">
                            <span>{{ scope.row.return_reason }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Order Date"
                          prop="delivery_date"
                          sortable
                          width="120"
                          align="center"
                        >
                          <template slot-scope="scope">
                            <span>{{ format_date(scope.row.created_at) }}</span>
                          </template>
                        </el-table-column>
                        
                        <el-table-column
                         
                          align="center"
                          label="Actions"
                          width="80"
                        >
                          <template slot-scope="scope">
                            <el-dropdown
                              @command="handleReturnView($event, scope.row)"
                              size="medium"
                            >
                              <i class="flaticon2-menu-1"></i>
                              <el-dropdown-menu
                                slot="dropdown"
                                class="action-dropdown"
                              >
                                <el-dropdown-item :command="1"
                                  ><i class="fa fa-eye"
                                    >View</i
                                  ></el-dropdown-item
                                >
                                <!-- <el-dropdown-item :command="2" ><i class="fa fa-credit-card"></i>Pay Now</el-dropdown-item>  -->
                              </el-dropdown-menu>
                            </el-dropdown>
                          </template>
                        </el-table-column>
                      </el-table>
                    </el-tab-pane> 
                    <el-tab-pane label="Estimation" name="estimation">
                       <el-table :data="estimation_list" border element-loading-text="Loading" style="width: 100%" v-loading="loading"> 
                  <el-table-column label="Status" sortable width="130" prop = "status" align="center" >
                    <template slot-scope="scope">
                      <span v-if="scope.row.estimation_status == 'Saved'">
                        <span v-if="new Date() > new Date(scope.row.delivery_date)">
                          <el-tag type="danger" size="medium" >Expired</el-tag> 
                        </span>
                        <span v-else>
                            <el-tag type="success" size="medium">{{scope.row.estimation_status }}</el-tag>                            
                        </span>      
                      </span>
                      <span v-else>
                        <el-tag type="success" size="medium" v-if="scope.row.estimation_status == 'Saved'">{{scope.row.estimation_status }}</el-tag>
                        <el-tag size="medium" v-if="scope.row.estimation_status == 'Sent'">{{scope.row.estimation_status }}</el-tag>                
                      </span>       
                    </template>
              </el-table-column>             
                  <el-table-column label="Estimation #" sortable width="150" >
                    <template slot-scope="scope">
                      <el-tag slot="reference" size="mini" ><a @click.prevent="viewEstimation(scope.row.id)">{{(scope.row.estimation_no) ? scope.row.estimation_no : ""}}</a></el-tag>                
                    </template>
                  </el-table-column> 
                   <el-table-column label="Date" prop="created_at" sortable width="120" >
                    <template slot-scope="scope">
                    {{scope.row.created_at|formatDate('DD MMM YYYY')}} 
                    </template>
                  </el-table-column> 
                  <el-table-column label="Customer Name" prop="customer_name" sortable width="200" >
                    <template slot-scope="scope">
                    {{scope.row.customer?scope.row.customer.first_name+' '+(scope.row.customer.last_name ? scope.row.customer.last_name : ''):""}}
                    </template>
                  </el-table-column>
                   <el-table-column label="Store" prop="store" sortable width="150" >
                    <template slot-scope="scope">
                    {{scope.row.website_details?scope.row.website_details.website_name:""}}
                    </template>
                  </el-table-column>
                    <el-table-column label="CUstomer Type" prop="customer_type" sortable width="150" >
                      <template slot-scope="scope">
                      {{scope.row.customer_type}} 
                      </template>
                    </el-table-column>    
                    <el-table-column label="Created By" prop="estimation_request_by" sortable width="200" >
                      <template slot-scope="scope">
                      {{scope.row.estimation_create_by}} 
                      </template>
                    </el-table-column>                    
                    
                     <el-table-column label="Amount" prop="total" sortable width="120" align="center">
                      <template slot-scope="scope">
                      ${{scope.row.total}}
                      </template>
                    </el-table-column> 
                    <el-table-column label="Expires on" prop="created_at" sortable width="120" >
                    <template slot-scope="scope">
                    {{scope.row.delivery_date|formatDate('DD MMM YYYY')}} 
                    </template>
                  </el-table-column> 
                    
                </el-table>
                    </el-tab-pane>  
                     <el-tab-pane label="Quotation" name="quotation">
                       <el-table :data="quotation_list" border element-loading-text="Loading" style="width: 100%" v-loading="loading">              
                  <el-table-column label="Quotation #" sortable width="130" >
                    <template slot-scope="scope">
                      <el-tag slot="reference" size="mini">{{(scope.row.quotation_no) ? scope.row.quotation_no : ""}}</el-tag>                
                    </template>
                  </el-table-column> 
                     <el-table-column label="Status" sortable width="130" prop = "status" align="center" >
                    <template slot-scope="scope">
                      <span v-if="scope.row.quotation_status == 'Saved'">
                      <span v-if="new Date() > new Date(scope.row.delivery_date) && new Date() == new Date(scope.row.delivery_date)">
                          <el-tag type="danger" size="medium" >Expired</el-tag> 
                        </span>
                        <span v-else>
                            <el-tag type="success" size="medium">{{scope.row.quotation_status }}</el-tag>                            
                        </span>      
                      </span>
                      <span v-else>
                        <el-tag type="success" size="medium" v-if="scope.row.quotation_status == 'Saved'">{{scope.row.quotation_status }}</el-tag>
                        <el-tag size="medium" v-if="scope.row.quotation_status == 'Sent'">{{scope.row.quotation_status }}</el-tag>                
                      </span>       
                    </template>
              </el-table-column> 
                   <el-table-column label="Created" prop="delivery_date"  width="90">
                    <template slot-scope="scope">
                    {{scope.row.created_at|formatDate('DD MMM YYYY')}} 
                    </template>
                  </el-table-column> 
                  <el-table-column label="Request by" prop="quotation_request_by" sortable min-width="150" >
                    <template slot-scope="scope">
                      <span v-if="scope.row.customer.first_name">{{scope.row.customer.first_name}}</span> <span v-if="scope.row.customer.last_name">{{scope.row.customer.last_name}}</span>
                    </template>
                  </el-table-column>
                   <el-table-column label="Store" prop="store"  width="120" >
                    <template slot-scope="scope">
                    {{scope.row.website_details?scope.row.website_details.website_name:""}}
                    </template>
                  </el-table-column>                              
                    <el-table-column label="Ship Via" prop="ship_via"  width="100" >
                      <template slot-scope="scope">
                      {{scope.row.ship_via}} 
                      </template>
                    </el-table-column>
                     <el-table-column label="Term" prop="term"  width="100" >
                      <template slot-scope="scope">
                      {{scope.row.term}} 
                      </template>
                    </el-table-column>
                     <el-table-column label="Sales Person" prop="sales_by" sortable width="150" >
                      <template slot-scope="scope">
                      {{scope.row.quotation_create_by}} 
                      </template>
                    </el-table-column>
                     <el-table-column label=" Total Amount" prop="total"  width="110" >
                      <template slot-scope="scope">
                      ${{scope.row.total}}
                      </template>
                    </el-table-column> 
                    
                </el-table>
                    </el-tab-pane>                      
                  </el-tabs>
                </template>
              </el-tab-pane>
              <el-tab-pane label="Addresses" name="address">              
                <el-tabs>                    
                  <el-tab-pane label="Billing">
                    <button
                        class="
                          btn
                          rounded-0
                          btn btn-sm btn-primary
                          font-weight-bold
                          text-uppercase
                          btn-primary
                          mb-4
                        "
                        @click.prevent="CreateAddress(1)"
                      >
                        <i class="fa fa-plus"></i>Add Address
                      </button>
                    <el-table
                      :data="billing_addresses"
                      element-loading-text="Loading"
                      stripe
                      border
                      fit
                    >
                      <el-table-column
                        label="Street"
                        prop="address_line_2"
                        sortable
                      >
                        <template slot-scope="scope">
                          <span>{{ scope.row.address_line_2 }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column
                        align="center"
                        label="Unit Number"
                        prop="address_line_1"
                        sortable
                      >
                        <template slot-scope="scope">
                          <span>{{ scope.row.address_line_1 }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column label="City" prop="city_name" sortable>
                        <template slot-scope="scope">
                          <span v-if="scope.row.city_details">{{scope.row.city_details.city_name }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="Province"
                        prop="state_name"
                        sortable
                      >
                        <template slot-scope="scope">
                          <span v-if="scope.row.state_details">{{ scope.row.state_details.state_name }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="Postal Code"
                        prop="post_code"
                        sortable
                      >
                        <template slot-scope="scope">
                          {{ scope.row.post_code }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        fixed="right"
                        align="center"
                        label="Actions"
                        width="80"
                      >
                        <template slot-scope="scope">
                          <el-dropdown
                            @command="handleActions($event, scope.row)"
                            size="medium"
                          >
                            <i class="flaticon2-menu-1"></i>
                            <el-dropdown-menu
                              slot="dropdown"
                              class="action-dropdown"
                            >
                              <el-dropdown-item :command="1"
                                ><i class="fa fa-pen"></i>Edit</el-dropdown-item
                              >
                              <el-dropdown-item :command="2"
                                ><i class="fa fa-trash"></i
                                >Delete</el-dropdown-item
                              >
                            </el-dropdown-menu>
                          </el-dropdown>
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-tab-pane>
                  <el-tab-pane label="Shipping">
                     <button
                        class="
                          btn
                          rounded-0
                          btn btn-sm btn-primary
                          font-weight-bold
                          text-uppercase
                          btn-primary
                          mb-4
                        "
                        @click.prevent="CreateAddress(2)"
                      >
                        <i class="fa fa-plus"></i>Add Address
                      </button>
                    <el-table
                      :data="shipping_addresses"
                      element-loading-text="Loading"
                      stripe
                      border
                      fit
                    >
                     
                      <el-table-column
                        label="Street"
                        prop="address_line_2"
                        sortable
                      >
                        <template slot-scope="scope">
                          <span>{{ scope.row.address_line_2 }}</span>
                        </template>
                      </el-table-column>
                       <el-table-column
                        align="center"
                        label="Unit Number"
                        prop="address_line_1"
                        sortable
                      >
                        <template slot-scope="scope">
                          <span>{{ scope.row.address_line_1 }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column label="City" prop="city_name" sortable>
                        <template slot-scope="scope">
                          <span v-if="scope.row.city_details">{{ scope.row.city_details.city_name }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="Province"
                        prop="state_name"
                        sortable
                      >
                        <template slot-scope="scope">
                          <span v-if="scope.row.state_details">{{ scope.row.state_details.state_name }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="Postal Code"
                        prop="post_code"
                        sortable
                      >
                        <template slot-scope="scope">
                          {{ scope.row.post_code }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        fixed="right"
                        align="center"
                        label="Actions"
                        width="80"
                      >
                        <template slot-scope="scope">
                          <el-dropdown
                            @command="handleActions($event, scope.row)"
                            size="medium"
                          >
                            <i class="flaticon2-menu-1"></i>
                            <el-dropdown-menu
                              slot="dropdown"
                              class="action-dropdown"
                            >
                              <el-dropdown-item :command="1"
                                ><i class="fa fa-pen"></i>Edit</el-dropdown-item
                              >
                              <el-dropdown-item :command="2"
                                ><i class="fa fa-trash"></i
                                >Delete</el-dropdown-item
                              >
                            </el-dropdown-menu>
                          </el-dropdown>
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-tab-pane>
                </el-tabs>
              </el-tab-pane>
              <el-tab-pane label="Credit Details" name="credit">
                <div class="row" style="margin-bottom: 10px">
                  <div class="col-sm-2">
                    <button
                      class="
                        btn
                        rounded-0
                        btn btn-sm btn-primary
                        font-weight-bold
                        text-uppercase
                        btn-primary
                        mb-4
                      "
                      @click.prevent="CreateCredits"
                    >
                      <i class="fa fa-plus"></i>Add Credit
                    </button>
                  </div>
                  <div class="col-sm-3" >
                    <label style="font-weight: bold; margin: 0px"
                      ><i class="flaticon-suitcase" style="color: #3699ff"></i>
                      Credit Limits : ${{ total_credits? (total_credits.toLocaleString("en-US")):'0.00' }}</label
                    >
                  </div>
                  <div class="col-sm-3" >
                    <label style="font-weight: bold; margin: 0px"
                      ><i class="flaticon-suitcase" style="color: green"></i>
                      Available Credit : ${{ available_credit?(available_credit.toLocaleString("en-US")):'0.00' }}</label
                    >
                  </div>
                  <div class="col-sm-3" >
                    <label style="font-weight: bold; margin: 0px"
                      ><i class="flaticon-suitcase" style="color: red"></i>
                       Balance Due: ${{ balance_credit?(balance_credit.toLocaleString("en-US")):'0.00' }}</label
                    >
                  </div>
                </div>
                <div class="row" style="margin-bottom: 10px">
                   <div class="col-sm-6">
                     <span><b>Request Credit Term </b>( {{view.credit_term?view.credit_term.term_name+' days':'Nil'}} )</span> 
                     <el-tag size="mini" style="margin-left: 10px" >
                       <a href="#" @click.prevent="ChangeTerm()">Change</a>
                     </el-tag
                      >
                   </div>
                </div>
                <el-table
                  :data="credit_details"
                  border
                  element-loading-text="Loading"
                  style="width: 100%"
                  v-loading="credit_loading"
                >
                  <el-table-column
                    label="Created Date"
                    prop="created_at"                    
                    width="120"
                  >
                    <template slot-scope="scope">
                      <span slot="reference">
                        {{ scope.row.created_at | formatDate("DD MMM YYYY") }}
                      </span>
                    </template>
                  </el-table-column>
                   <el-table-column
                    label="Credit Limit"
                    prop="approved_total_credit"
                    
                    width="120"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <span>$ {{ scope.row.approved_total_credit.toLocaleString("en-US") }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="Request Credit"
                    prop="credit_limit"                    
                    width="140"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <span>$ {{ scope.row.credit_limit.toLocaleString("en-US") }}</span>
                    </template>
                  </el-table-column>
                   <el-table-column
                    label="Approve Credit"
                    prop="credit_limit"                    
                    width="150"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <span>$ {{ scope.row.approved_credit.toLocaleString("en-US") }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="Available Credit"
                    prop="available_credit"                    
                    width="150"
                    align="center"
                  >
                    <template slot-scope="scope">
                      $ {{ scope.row.available_credit.toLocaleString("en-US") }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="Balance Due"
                    prop="balance_showing"                    
                    width="120"
                    align="center"
                  >
                    <template slot-scope="scope" style="color:red">
                      $ {{ scope.row.balance_showing.toLocaleString("en-US") }}
                    </template>
                  </el-table-column>
                   <el-table-column
                    label="Status"
                    prop="status"
                    sortable
                    width="100"
                  >
                    <template slot-scope="scope">
                      <span v-if="scope.row.status == 'Approved'"><b>{{ scope.row.status }}</b> <span v-if="scope.row.record_status ==1"><i class="flaticon2-correct text-success font-size-h5"></i></span></span>
                      <span v-else class="red"><b>{{ scope.row.status }}</b></span>
                    </template>
                  </el-table-column>
                   <el-table-column
                    label="Approved/Rejected by"
                    prop="verified_by"
                    width="200"                    
                  >
                    <template slot-scope="scope">
                      {{ scope.row.verified_by }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="Document Name"
                    prop="document_name"
                    :min-width="200"
                  >
                    <template slot-scope="scope">
                      {{ scope.row.document_name }}
                    </template>
                  </el-table-column>
                   <el-table-column
                    label="Reject reason"
                    prop="document_name"
                    :min-width="200"
                  >
                    <template slot-scope="scope">
                      {{ scope.row.reject_reason }}
                    </template>
                  </el-table-column>
                 
                   <el-table-column fixed="right" align="center" label="Actions" width="80">
                      <template slot-scope="scope">
                        <el-dropdown @command="handleActionsCredit($event,scope.row)" size="medium">
                          <i class="flaticon2-menu-1"></i>
                          <el-dropdown-menu slot="dropdown" class="action-dropdown">
                            <el-dropdown-item :command="1" >
                              <span v-if="scope.row.status == 'Pending'"><i class="fa fa-pen" ></i>Edit</span>
                              <span v-else><i class="fa fa-eye"></i>view</span>
                              </el-dropdown-item>                    
                          </el-dropdown-menu>
                        </el-dropdown>
                      </template>
              </el-table-column>
                </el-table>
              </el-tab-pane>
           
              <el-tab-pane label="Account Receivable" name="account_receivable">
                <el-tabs type="border-card">
                  <el-tab-pane>
                    <span slot="label"><i class="el-icon-s-finance"></i> Record Payment</span>
                    <button class="btn rounded-1 btn btn-sm btn-primary font-weight-bold text-uppercase btn-primary mb-4" @click.prevent="addStoreCredit">
                      <i class="fa fa-plus"></i>Record Payment
                    </button>
                  <AccountReceivable></AccountReceivable>
                  </el-tab-pane>
                  <el-tab-pane label="Config">
                     <span slot="label"><i class="el-icon-money"></i> Credit Memo</span>
                    <CreditMemo></CreditMemo>
                  </el-tab-pane>                 
                </el-tabs>               
              </el-tab-pane>
              <el-tab-pane label="Payment Method" name="payment_method">
                <el-row align="right">
                  <button
                    class="
                      btn
                      rounded-3
                      btn btn-sm btn-primary
                      font-weight-bold
                      text-uppercase
                      btn-primary
                      mb-4
                    "
                    @click="AddCard()"
                  >
                    <i class="fa fa-plus"></i>Add New Card
                  </button>
                  <!-- <button class="btn rounded-3 btn btn-sm btn-warning font-weight-bold text-uppercase btn-primary mb-4 ml-5" @click="EditCard()" ><i class="fa fa-pen"></i>Edit</button>                                  
                     <button class="btn rounded-3 btn btn-sm btn-danger font-weight-bold text-uppercase btn-primary mb-4 ml-5" @click="DeleteCard()" ><i class="fa fa-trash"></i>Delete</button>                   -->
                </el-row>
                <el-row :gutter="20" class="mt-10">
                  <el-col
                    :span="8"
                    v-for="(card, index) in card_list"
                    :key="index"
                  >
                    <div class="grid-content bg-purple credit-card">
                      <div class="credit_card__inner">
                        <div class="card-front">
                          <img
                            class="card-front__image"
                            :src="backgroundImage"
                          />
                          <img
                            class="card-front__symbol"
                            :src="
                              'https://stagingurl.tk/ctshub_api/public/images/card/' +
                              lower(card.brand) +
                              '.png'
                            "
                          />
                          <!-- <img class="card-front__chip" :src="chipImage" /> -->
                          <p class="card-front__chip">
                            <input
                              name="myfield"
                              type="radio"
                              :value="card.id"
                              :checked="card.cardStatus"
                              style="
                                height: 35px;
                                width: 35px;
                                vertical-align: middle;
                              "
                              @change="cardActive(card.id)"
                            />
                          </p>
                          <p class="card-front__number">
                            **** **** **** {{ card.last_4 }}
                          </p>
                          <div class="card-front__info">
                            <p>Expires</p>
                            <p class="card-front__expires value">
                              {{ card.cardMonth }} /
                              {{ card.cardYear }}
                            </p>
                          </div>
                          <div class="card-front__info left">
                            <p>Card Owner</p>
                            <p class="value">{{ card.cardName }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </el-tab-pane>
            </el-tabs>
          </template>
        </div>
      </div>
      <v-dialog />
    </div>
    <!--end: Settings-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  getCustomer,
  deleteAddress,
  getOrder,
  getCustomerCredit,
  getAllInvoice,
  getCardDetails,
  cardActive,
  getYearsales,
} from "@/api/customer";
import {generateInvoice} from "@/api/order";
import UpdateAddress from "@/components/CreateAddress";
import CreateAddress from "@/components/CreateAddress/addAddress";
//import CreateAddress from "@/components/CreateAddress/add.vue";
import { globalEvents } from "@/utils/globalEvents";
import CreateCredit from "@/components/CreateCustomer/credit.vue";
import AddCredit from "@/components/CreateCustomer/add_credit.vue";
import { getPackingCustomer } from "@/api/packingslip";
import { deleteInvoice } from "@/api/invoice";
import RecoredPayment from "@/components/Payment/recordpayment";
//import CardPayment from '@/components/Payment/creditcard';
import AccountReceivable from "@/components/AccountReceivable";
import CreditMemo from "@/components/CreditMemo";
import moment from "moment";
//import PaymentCard from "@/components/Payment/cardPayment.vue";
import AddCreditCard from "@/components/CreateCustomer/AddPaymentCard.vue";
import payNow from "@/components/Payment/payNow";
import CreditTerm from "@/components/CreateCustomer/credit_term.vue";
import CreditView from "@/components/CreateCustomer/view_credit.vue";
import StoreCredit from '@/components/StoreCredit';

export default {
  name: "view-customer",
  data() {
    return {
      loading: false,
      credit_loading:false,
      view: [],
      address: [],
      credit: [],
      oredr_details: [],
      activeName: "order",
      billing_addresses: [],
      shipping_addresses: [],
      pending_order: [],
      cancel_order: [],
      packing_list: [],
      credit_details: [],
      complete_order: [],
      return_order : [],
      refund_order : [],
      back_list: [],
      activeTab: "address",
      credit_limit: 0,
      invoice_list: [],
      card_list: [],
      backgroundImage:
        "https://stagingurl.tk/ctshub_api/public/images/card/card-5.jpg",
      symbolImage: String,
      cardNumber: "**** **** **** 4352",
      expireMonth: "02",
      expireYear: "2022",
      name: "Pratheep KS",
      active: 1,
      card_id: null,
      balance_amount: null,
      sales_year: 0,
      available_credit:0,
      total_credits : 0,
      balance_credit : 0,
      estimation_list : [],
      quotation_list : [],
      image_path:null,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Customer Management",
        route: "/customer/list"
      },
      {
        title: "View Customer"
      },
    ]);
    globalEvents.$on("invoiceAdded", (added) => {
      this.fetchData(1);
      if (added) {
        this.fetchData(1);
      }
    });

    globalEvents.$on("cardAdded", (added) => {
      this.fetchData(1);
      if (added) {
        this.fetchData(1);
      }
    });
     globalEvents.$on("CreditAdded", (credit) => {        
       this.credit_details = [];
       this.credit_loading = true;    
        if (credit) {
          
          //this.credit_details.push(credit);
          this.fetchData(1);
          this.credit_loading = false;    
        
        }
    });
      globalEvents.$on("cardAdded", (added) => {
      this.fetchData(1);
      if (added) {
        this.fetchData(1);
      }
    });
     globalEvents.$on("creditTerm", (added) => {    
      if (added) {
        this.fetchData(1);
      }
    });
    globalEvents.$on('InvoicePaid', added => {
      this.fetchData(1);
      if (added) {
        this.fetchData(1)
      }
    })
      
  },
  components: {
    AccountReceivable,
    CreditMemo
  },
  created() {
    this.fetchData(1);
    globalEvents.$on("AddressAdded", (added) => {
      if (added) {
        this.fetchData(1);
      }
    });
   
  },

  methods: {
    lower(name) {
      return name.toLowerCase();
    },
    cardActive(card_id) {
      cardActive(card_id).then((response) => {
        this.loading = true;
        if (response.data) {
          this.loading = false;
        }
      });
    },
    viewOrder(order_id) {
      this.$router.push({ path: "/order/view/" + order_id });
    },
    viewPacking(packing_id) {
      this.$router.push({ path: "/packingslip/view/" + packing_id });
    },
    viewInvoice(invoice_id) {
      this.$router.push({ path: "/invoice/view/" + invoice_id });
    },
    viewBackorder(backorder_id) {
      this.$router.push({ path: "/backorders/view/" + backorder_id });
    },

    viewCancelOrder(cancel_id) {
      this.$router.push({ path: "/cancel/order/view/" + cancel_id });
    },
     viewReturnorder(return_id){
        this.$router.push({path:'/return/order/view/'+return_id})
      },
    AddCard() {
      this.$modal.show(
        AddCreditCard,
        { customer_id: parseInt(this.$route.params.customer_id) },
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: true,
          adaptive: true,
          width: "450px"
        }
      );
    },
    addStoreCredit() {
      let customerId = parseInt(this.$route.params.customer_id)
     
      this.$modal.show(StoreCredit, {
        customer_id: customerId
      }, {
        draggable: true,
        transition: 'pop-out',
        overflow: 'auto',
        height: 'auto',
        clickToClose: false,
        adaptive: true,
        width: '900'
      })
    },
    getOrderPaymentStatus(row) {
      let date = new Date(row.delivery_date);
      var periods = {
        month: 30 * 24 * 60 * 60 * 1000,
        week: 7 * 24 * 60 * 60 * 1000,
        day: 24 * 60 * 60 * 1000,
        hour: 60 * 60 * 1000,
        minute: 60 * 1000,
      };
      var diff = Date.now() - date.getTime();
      var status = "";
      var status_msg = "";
      if (row.payment_status === "partially_paid") {
        status_msg =
          '<span class="partially-span el-tag el-tag--mini el-tag--light">Partially Paid</span>';
      } else if (
        row.payment_status == "unpaid" &&
        Math.floor(diff / periods.day) > 0
      ) {
        if (diff > periods.month) {
          status = Math.floor(diff / periods.month) + " month ago";
        } else if (diff > periods.week) {
          status = Math.floor(diff / periods.week) + " week ago";
        } else if (diff > periods.day) {
          status = Math.floor(diff / periods.day) + " days ago";
        } /*else if (diff > periods.hour) {
                    status = Math.floor(diff / periods.hour) + " hours ago";
                } else if (diff > periods.minute) {
                    status = Math.floor(diff / periods.minute) + " minutes ago";
                }*/
        status_msg =
          '<span class="el-tag el-tag--mini el-tag--danger">Over Due</span><div>' +
          status +
          "</div>";
      } else if (row.payment_status === "paid") {
        status_msg = '<span class="paid-span el-tag el-tag--mini el-tag--success">Paid</span>';
          if(row.invoice.term_paid_amount > 0){
            status_msg = '<span class="paid-span el-tag el-tag--mini el-tag--success">Term</span>';
          }
      } else if (row.payment_status === "unpaid") {
        status_msg =
          '<span class="el-tag el-tag--mini el-tag--danger">Unpaid</span>';
      }

      return status_msg;
    },
    getPaymentStatus(row) {
      let date = new Date(row.payment_due_date);
      var periods = {
        month: 30 * 24 * 60 * 60 * 1000,
        week: 7 * 24 * 60 * 60 * 1000,
        day: 24 * 60 * 60 * 1000,
        hour: 60 * 60 * 1000,
        minute: 60 * 1000,
      };
      var diff = Date.now() - date.getTime();
      var status = "";
      var status_msg = "";
      if (row.invoice_status === "partially_paid") {
        status_msg = '<span class="partially-span">Partially Paid</span>';
      } else if (
        row.invoice_status == "unpaid" &&
        Math.floor(diff / periods.day) > 0
      ) {
        if (diff > periods.month) {
          status = Math.floor(diff / periods.month) + " month ago";
        } else if (diff > periods.week) {
          status = Math.floor(diff / periods.week) + " week ago";
        } else if (diff > periods.day) {
          status = Math.floor(diff / periods.day) + " days ago";
        } /*else if (diff > periods.hour) {
                    status = Math.floor(diff / periods.hour) + " hours ago";
                } else if (diff > periods.minute) {
                    status = Math.floor(diff / periods.minute) + " minutes ago";
                }*/
        status_msg =
          '<span class="overdue-span">Over Due</span><span>' +
          status +
          "</span>";
      } else if (row.invoice_status === "paid") {
        status_msg = '<span class="paid-span el-tag el-tag--mini el-tag--success">Paid</span>';
          if(row.term_paid_amount > 0){
            status_msg = '<span class="paid-span el-tag el-tag--mini el-tag--success">Term</span>';
          }
      } else if (row.invoice_status === "unpaid") {
        status_msg =  '<span class="el-tag el-tag--mini el-tag--danger">Unpaid</span>';
      }

      return status_msg;
    },
    getPaymentStatus1(row){
            let date = new Date(row.order.delivery_date);
            var periods = {
                month: 30 * 24 * 60 * 60 * 1000,
                week: 7 * 24 * 60 * 60 * 1000,
                day: 24 * 60 * 60 * 1000,
                hour: 60 * 60 * 1000,
                minute: 60 * 1000
            };
            var diff = Date.now() - date.getTime();
            var status = "";
            var status_msg = "";
            if(row.order.payment_status === 'partially_paid'){
                status_msg = '<span class="partially-span el-tag el-tag--mini el-tag--light">Partially Paid</span>';
            }else if(row.order.payment_status == 'unpaid' && Math.floor(diff / periods.day) > 0){
                if (diff > periods.month) {
                    status = Math.floor(diff / periods.month) + " month ago";
                } else if (diff > periods.week) {
                    status = Math.floor(diff / periods.week) + " week ago";
                } else if (diff > periods.day) {
                    status = Math.floor(diff / periods.day) + " days ago";
                } /*else if (diff > periods.hour) {
                    status = Math.floor(diff / periods.hour) + " hours ago";
                } else if (diff > periods.minute) {
                    status = Math.floor(diff / periods.minute) + " minutes ago";
                }*/
                status_msg = '<span class="el-tag el-tag--mini el-tag--danger">Over Due</span><div>'+status+'</div>';
            }else if(row.order.payment_status === 'paid'){
                status_msg = '<span class="el-tag el-tag--mini el-tag--success">Paid</span>';
                if(row.order.invoice.term_paid_amount > 0){
                  status_msg = '<span class="el-tag el-tag--mini el-tag--success">Term</span>';
                }
            }else if(row.order.payment_status === 'unpaid'){
                status_msg = '<span class="el-tag el-tag--mini el-tag--danger">Unpaid</span>';
            }

            return status_msg;
        },
    getTermStatus(row) {
      var status_msg = "";
      if(row.term_paid_amount > 0){
        const date = new Date();
       var termPeriods = this.view.credit_term.term_name
       var candaTime = moment.tz.setDefault('America/Toronto');
       var invoiceDate = candaTime(row.invoice_date).format('MM/DD/YYYY');
       var term_close_date = candaTime(invoiceDate).add(termPeriods, 'days').format('MM/DD/YYYY');
       var current_date = candaTime(date).format('MM/DD/YYYY');
      const date1 = new Date(current_date);
      const date2 = new Date(term_close_date);
      var diff = date2.getTime() - date1.getTime();   
      var daydiff = diff / (1000 * 60 * 60 * 24);
      console.log(date1,'days',term_close_date);
      if(daydiff>0){
          status_msg ='<span class="due-span">Due</span><span>' + daydiff +'days' +'</span>';
        }else{
          status_msg ='<span class="overdue-span">Over Due</span><span>' + daydiff +'days' +'</span>';
        }
      }else{
           if (row.invoice_status === "paid") {
        status_msg = '<span class="paid-span el-tag el-tag--mini el-tag--success">Paid</span>';
         
      } else if (row.invoice_status === "unpaid") {
        status_msg =  '<span class="el-tag el-tag--mini el-tag--danger">Unpaid</span>';
      }
      }      
      return status_msg;
    },
   
    fetchData() {
      this.loading = true;
      getCustomer(this.$route.params.customer_id).then((response) => {
        console.log(response,'gdfg');
        this.view = response.data.data;
        this.image_path = response.data.image_path;
        this.credit.push(response.data.data.customer_credit);

        this.shipping_addresses = response.data.data.customer_address.filter(
          (address) => {
            return address.type == "shipping";
          }
        );
        this.billing_addresses = response.data.data.customer_address.filter(
          (address) => {
            return address.type == "billing";
          }
        );
        this.loading = false;
      });
      getOrder(this.$route.params.customer_id).then((response) => {       
        this.complete_order = response.data.completed;
        this.pending_order = response.data.pending_order;
        this.cancel_order = response.data.cancel_order;
        this.refund_order = response.data.refund_order;
        this.back_list = response.data.back_order;
        this.oredr_details = response.data.data;
        this.return_order = response.data.return_order;
        this.estimation_list = response.data.estimation;
        this.quotation_list = response.data.quotation;
        //console.log(this.oredr_details);
        this.loading = false;
      });
      getPackingCustomer(this.$route.params.customer_id).then((response) => {
        this.packing_list = response.data.data;
        this.loading = false;
      });
      getCardDetails(this.$route.params.customer_id).then((response) => {
        this.card_list = response.data.data;
        this.loading = false;
      });
      getCustomerCredit(this.$route.params.customer_id).then((response) => {
        this.credit_details = response.data.list;
        this.available_credit = response.data.data.available_credit;
        this.total_credits = response.data.data.approved_total_credit; 
        this.balance_credit = response.data.data.balance_showing;        
        this.loading = false;
      });
    
      getAllInvoice(this.$route.params.customer_id).then((response) => {
        this.invoice_list = response.data.data;
        this.loading = false;
      });

      getYearsales(this.$route.params.customer_id).then((response) => {
        this.sales_year = response.data.data.total.toFixed(2);
        this.loading = false;
      });
    },
    editCustomer(customer_id) {
      this.$router.push({
        path: "/customer/edit/" + customer_id
      });
    },
     viewEstimation(estimation_id){
         this.$router.push({path:'/estimate/view/'+estimation_id})
       },

     CreateAddress(val) {
      var titleName = "Shipping Address";
      if (val == "1") {
        titleName = "Billing Address";
      }
      this.$modal.show(
        CreateAddress,
        {
          customer_id: parseInt(this.$route.params.customer_id),
          address_type: val,
          label: {
            button: "Create",
            title: titleName,
          },
        },
        {
          draggable: true,
          transition: "pop-out",
          overflow: "auto",
          height: "auto",
          clickToClose: false,
          adaptive: true,
          width: "600px"
        }
      );
    },
   
    handleActions(evt, row) {
      if (evt === 1) {
        this.$modal.show(
          UpdateAddress,
          {
            customer_id: row.customer_id,
            address: row,
            label: {
              button: "Update",
              title: "Update Address"
            },
          },
          {
            draggable: true,
            transition: "pop-out",
            overflow: "auto",
            height: "auto",
            clickToClose: false,
            adaptive: true
          }
        );
      } else if (evt === 2) {
        this.$bvModal
          .msgBoxConfirm("Are you sure you want to delete ?", {
            title: "Please Confirm",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true
          })
          .then((value) => {
            if (value) {
              this.loading = true;
              deleteAddress(row.id).then((response) => {
                if (response.data.status) {
                  this.fetchData();
                  this.$showResponse("success", response.data.message);
                }
                this.loading = false;
              });
            } else {
              return false;
            }
          });
      }
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    CreateCredit(row) {
      this.$modal.show(
        CreateCredit,
        {
          customer_id: row.customer_id,
        },
        {
          draggable: true,
          transition: "pop-out",
          overflow: "auto",
          height: "auto",
          clickToClose: false,
          adaptive: true,
          width: "600"
        }
      );
    },
    ChangeTerm() {
      this.$modal.show(
        CreditTerm,
        {
          customer_id: parseInt(this.$route.params.customer_id),
        },
        {
          draggable: true,
          transition: "pop-out",
          overflow: "auto",
          height: "auto",
          clickToClose: false,
          adaptive: true,
          width: "600"
        }
      );
    },

      handleActionsCredit(evt, row) {
            if (evt === 1) {
            // this.$router.push({path:'/credit/approve/'+row.id});
              this.$modal.show(
                CreditView,
                { credit_id: row.id },
                {
                  draggable: true,
                  transition: "pop-out",
                  height: "auto",
                  clickToClose: true,
                  adaptive: true,
                  width: "800px"
                }
              );
            }
      },
      handleCompleteOrder(evt, row){
          if (evt === 1) {
            this.$router.push({ path: "/order/view/" + row.order.id });
        }
      },
    handleActionsInvoice(evt, row) {
      if (evt === 1) {
        this.$router.push({
          path: "/invoice/view/" + row.order_id
        });
      } else if (evt === 2) {
        this.$bvModal
          .msgBoxConfirm("Are you sure you want to delete ?", {
            title: "Please Confirm",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true
          })
          .then((value) => {
            if (value) {
              this.loading = true;
              deleteInvoice(row.id).then((response) => {
                if (response.data.status) {
                  this.fetchData(1);
                  this.$showResponse("success", response.data.message);
                }
                this.loading = false;
              });
            } else {
              return false;
            }
          });
      } else if (evt === 3) {
        this.$modal.show(
          RecoredPayment,
          { invoice: row },
          {
            draggable: true,
            transition: "pop-out",
            height: "auto",
            clickToClose: true,
            adaptive: true,
            width: "450px"
          }
        );
      } else if (evt === 4) {
        this.$modal.show(
          payNow,
          { invoice: row },
          {
            draggable: true,
            transition: "pop-out",
            height: "auto",
            clickToClose: true,
            adaptive: true,
            width: "450px"
          }
        );
      } else if (evt === 5) {
        this.$modal.show(
          payNow,
          { invoice: row },
          {
            draggable: true,
            transition: "pop-out",
            height: "auto",
            clickToClose: true,
            adaptive: true,
            width: "450px"
          }
        );
      }
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD-MM-YYYY");
      }
    },
    CreateCredits() {
      let customerId = parseInt(this.$route.params.customer_id);
      this.$modal.show(
        AddCredit,
        {
          customer_id: customerId,
        },
        {
          draggable: true,
          transition: "pop-out",
          overflow: "auto",
          height: "auto",
          clickToClose: false,
          adaptive: true,
          width: "800"
        }
      );
    },
     handleOrderView(evt, row) {
      if (evt === 1) {
        this.$router.push({
          path: "/order/view/" + row.id
        });
      } else if (evt === 2) {
         this.loading = true;                     
              generateInvoice(row.id).then(response => {     
                this.Invoice = response.data.data   
                let credit = response.data.credit             
                  this.$modal.show(payNow, {invoice:this.Invoice,credit:credit}, {
                    draggable: true,
                    transition: 'pop-out',
                    height: 'auto',
                    clickToClose: true,
                    adaptive: true,
                    width: '450px'
                });
                this.loading = false;               
              });  
      } else {
        return false;
      }
    },

    handleView(evt, row) {
      if (evt === 1) {
        this.$router.push({
          path: "/order/view/" + row.id
        });
      } else if (evt === 2) {
        let customerId = this.$route.params.customer_id;
        this.$router.push({
          path: "/customer/packingslip/" + row.id + "/" + customerId
        });
      } else {
        return false;
      }
    },

    handleBackView(evt, row) {
      if (evt === 1) {
        this.$router.push({
          path: "/backorders/view/" + row.order.id
        });
      }
    },
    handlePacking(evt, row) {
      if (evt === 1) {
        this.$router.push({
          path: "/packingslip/view/" + row.packing_id
        });
      }
    }
  }
};
</script>
<style scoped lang="scss">
.credit-card {
  font-family: "Source Code Pro", monospace;
  max-width: 420px;
  width: 100%;
  height: 200px !important;
  background-color: transparent;
  color: white;
  -webkit-perspective: 1000px;
  perspective: 1000px;
  display: inline-block;
}
.credit_card__inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  -webkit-transition: -webkit-transform 0.6s;
  transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  transition: transform 0.6s, -webkit-transform 0.6s;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
[type="radio"]:checked + label:after {
  background-color: red;
  border: 2px solid red;
}
</style>

<style scoped lang="scss">
$x-space: 24px;
$y-space: 16px;
.card-front {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 100;
  &__image {
    width: 100%;
    height: 100%;
    border-radius: 16px;
  }
  &__number {
    position: absolute;
    font-size: 26px;
    top: 35%;
    left: 3px;
  }
  &__chip {
    position: absolute;
    top: $y-space;
    left: $x-space;
    height: 44px;
  }
  &__symbol {
    position: absolute;
    top: $y-space;
    right: $x-space;
    height: 40px;
    max-width: 90px;
  }
  &__info {
    position: absolute;
    bottom: $y-space;
    right: $x-space;
    color: white;
    text-align: left;
    margin: 0;
    &.left {
      left: $x-space;
    }
    .value {
      font-weight: bold;
    }
    p {
      margin: 0;
    }
  }
  &__expires {
    right: auto;
    left: $x-space;
  }
  &__typeImg {
    max-width: 100%;
    object-fit: contain;
    max-height: 100%;
    object-position: top right;
  }
  @media screen and (max-width: 480px) {
    &__number {
      font-size: 22px;
    }
    &__info {
      font-size: 12px;
    }
    &__chip {
      height: 34px;
    }
    &__symbol {
      height: 38px;
    }
  }
  @media screen and (max-width: 360px) {
    &__number {
      font-size: 18px;
    }
  }
}
</style>