<template>
  <div>
    <div slot="header" class="modal-header popup-header">
    <span class="modal-title-popup"><i class="flaticon-interface-9" aria-hidden="true"></i>Invoices</span>
      <i style="float: right; padding: 3px 0" class="model-close el-icon-close icon-pointer" @click.prevent="closeForm"></i>
    </div>
    <div class="card card-custom">
        <div class="card-body p-0">
            <!--begin: Employees-->
            <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
                <!--begin: Employees Body-->
                <div class="row justify-content-center ">
                    <div class="col-xl-12">
                      <el-row>
                        <el-col>
                          <div class="row">
                            <div class="col-sm-12">
                              <el-table :data="invoices" border element-loading-text="Loading" style="width: 100%" v-loading="loading">
                                <el-table-column type="index" label='Sl. No.'>
                                </el-table-column>
                                <el-table-column align="center" label='Status' prop="id" sortable width="140">
                                  <template slot-scope="scope">
                                      <div v-html="getPaymentStatus(scope.row)"></div>
                                  </template>
                                </el-table-column>
                                <el-table-column align="center" label='Due' prop="id" sortable>
                                  <template slot-scope="scope">
                                    {{scope.row.payment_due_date | formatDate}}
                                  </template>
                                </el-table-column>
                                <el-table-column align="center" label='Paid Date' prop="id" sortable>
                                  <template slot-scope="scope">
                                    {{(scope.row.paid_date) ? scope.row.paid_date.payment_date : '' | formatDate}}
                                  </template>
                                </el-table-column>
                                <el-table-column align="center" label='Invoice #' prop="id" sortable>
                                  <template slot-scope="scope">
                                  <el-tag slot="reference" size="mini">{{(scope.row.invoice_number) ? scope.row.invoice_number : ""}}</el-tag>
                                  </template>
                                </el-table-column>
                                <el-table-column align="center" label='Amount Due' prop="id" sortable>
                                  <template slot-scope="scope">
                                  {{(scope.row.total_amount) ? '$'+scope.row.total_amount : '$0.00'}}
                                  </template>
                                </el-table-column> 
                                <!-- <el-table-column align="center" label='Amount Paid' prop="id" sortable>
                                  <template slot-scope="scope">
                                  {{(scope.row.paid_date) ? scope.row.paid_date.due_amount : '$0.00'}}
                                  </template>
                                </el-table-column> -->
                                <el-table-column align="center" label='Balance Due' prop="balance_amount" sortable>
                                  <template slot-scope="scope">
                                  {{(scope.row.balance_amount) ? '$'+scope.row.balance_amount : '$0.00'}}
                                  </template>
                                </el-table-column>
                              </el-table>
                            </div>
                          </div>
                         </el-col>
                       </el-row>
                    </div>
                </div>
                <!--end: Employees Body-->
            </div>
        </div>
        <!--end: Employees-->
    </div>
    <v-dialog/>
  </div>
</template>

<script>
import {getInvoicesByStoreCreditId} from "@/api/customer";
export default {
  name: 'clear-invoice',  
  props: {
    store_credit: {
      type: Object
    },
  },
  data() {
    return {
      loading: false,
      invoices: [],
    }
  },
  computed:{
  },
  mounted() {
  },
  components:{
  },
  created() {
    this.fetchData()
  },
  methods: {
    closeForm() {
        this.$emit('close')
    },
    fetchData() {
      this.loading = true
      this.getInvoicesByStoreCreditId();
    },
    getInvoicesByStoreCreditId(){
      getInvoicesByStoreCreditId(this.store_credit.id).then(response => {
        this.invoices = response.data.data;
        this.loading = false
      });
    },
    getPaymentStatus(row){
        let date = new Date(row.payment_due_date);
        var periods = {
            month: 30 * 24 * 60 * 60 * 1000,
            week: 7 * 24 * 60 * 60 * 1000,
            day: 24 * 60 * 60 * 1000,
            hour: 60 * 60 * 1000,
            minute: 60 * 1000
        };
        var diff = Date.now() - date.getTime();
        var status = "";
        var status_msg = "";
        if(row.invoice_status === 'partially_paid'){
            status_msg = '<span class="partially-span el-tag el-tag--mini el-tag--light">Partially Paid</span>';
        }else if(row.invoice_status == 'unpaid' && Math.floor(diff / periods.day) > 0){
            if (diff > periods.month) {
                status = Math.floor(diff / periods.month) + " month ago";
            } else if (diff > periods.week) {
                status = Math.floor(diff / periods.week) + " week ago";
            } else if (diff > periods.day) {
                status = Math.floor(diff / periods.day) + " days ago";
            } /*else if (diff > periods.hour) {
                status = Math.floor(diff / periods.hour) + " hours ago";
            } else if (diff > periods.minute) {
                status = Math.floor(diff / periods.minute) + " minutes ago";
            }*/
            status_msg = '<span class="el-tag el-tag--mini el-tag--danger">Over Due</span><div>'+status+'</div>';
        }else if(row.invoice_status === 'paid'){
            status_msg = '<span class="paid-span el-tag el-tag--mini el-tag--success">Paid</span>';
            if(row.term_paid_amount > 0){
            status_msg = '<span class="paid-span el-tag el-tag--mini el-tag--success">Term</span>';
          }
        }else if(row.invoice_status === 'unpaid'){
            status_msg = '<span class="el-tag el-tag--mini el-tag--danger">Unpaid</span>';
        }

        return status_msg;
    }
  }
};
</script>