<template>
 <div>
   <div slot="header" class="modal-header popup-header">
      <span class="modal-title-popup"><i class="fa fa-plus"></i>Add Record Payment</span>
      <i style="float: right; padding: 3px 0" class="model-close el-icon-close icon-pointer" @click.prevent="closeForm"></i>
   </div>
   <div class="card card-custom" v-loading="loading">
      <div class="card-body p-0">
         <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
            <div class="row ">
               <div class="col-xl-6">
                  <el-form :model="form"  ref="form" label-position="left" label-width="150px" :disabled="form.submiting">
                     <el-form-item label="Date" prop="payment_date" hidden>
                        <el-form-item prop="payment_date" style="margin-bottom:0px;">
                        <el-date-picker  v-model="form.payment_date" placeholder="DD-MM-YYYY" type="date" format="dd MMM yyyy" style="width:100%;" > </el-date-picker>
                        <span class="form-err" v-if="form.errors.has('payment_date')" >{{form.errors.get('payment_date')}}</span>
                        </el-form-item>
                     </el-form-item>
                     <el-form-item label="Payment Method" prop="payment_method">
                        <el-select clearable filterable v-model="form.payment_method" placeholder="Select Pyament Method"  style="width:100%" :allow-create="true" :disabled="select_invoice_status == true">
                           <el-option v-for="(method,index) in payment_methods" 
                           :key="index" 
                           :label="method.method_name" 
                           :value="method.method_name" 
                           :disabled="method.method_name == 'Term' || method.method_name == 'Credit Memo'|| method.method_name == 'Credit Card'" 
                            auto-complete="nope"
                           ></el-option>
                        </el-select>
                        <span class="form-err" v-if="form.errors.has('payment_method')" >{{form.errors.get('payment_method')}}</span>
                     </el-form-item>
                     <el-form-item label="Bank A/C No" prop="bank_acount" v-if="form.payment_method == 'Bank'" >                       
                       <el-input      
                          v-model="form.bank_acount_no" auto-complete="nope" :disabled="select_invoice_status == true">
                        </el-input>                        
                        <span class="form-err" v-if="bank_account_error" >{{bank_account_error}}</span>
                     </el-form-item >
                        <el-form-item label="Cheque No" prop="cheque_no" v-if="form.payment_method == 'Cheque'" auto-complete="nope">                       
                       <el-input      
                          v-model="form.cheque_no" :disabled="select_invoice_status == true">
                        </el-input>                        
                        <span class="form-err" v-if="cheque_error" >{{cheque_error}}</span>
                     </el-form-item>
                      <el-form-item label="Transaction ID" prop="cheque_no" v-if="form.payment_method == 'Email'">                       
                       <el-input      
                          v-model="form.transaction_id" auto-complete="nope" :disabled="select_invoice_status == true">
                        </el-input>                        
                        <span class="form-err" v-if="email_transaction_error" >{{email_transaction_error}}</span>
                     </el-form-item>
                     <el-form-item label="Amount" prop="status">
                        <el-form-item prop="amount" style="margin-bottom:0px;">
                           <el-input prefix-icon="fas fa-dollar-sign" v-model="form.amount" @change="checkAmount(form.amount)" auto-complete="nope" :disabled="select_invoice_status == true"></el-input>                        
                        <span class="form-err" v-if="form.errors.has('amount')" >{{form.errors.get('amount')}}</span> 
                        </el-form-item>                        
                     </el-form-item>
                   
                  </el-form>
               </div>
                 <div class="col-xl-12 mt-5">
                      <el-row>
                        <el-col :span="11">
                          <div class="clear-invoice">
                            <span>Record Pay Amount :  </span>
                            <span><strong>${{(form.amount) ? form.amount : 0.00}}</strong></span>
                          </div>
                        </el-col>
                        <el-col :span="11">
                          <div class="clear-invoice">
                            <span>Record Pay Balance :  </span>
                            <span><strong>${{form.credit_balance ? form.credit_balance : 0.00}}</strong></span>
                          </div>
                        </el-col>
                      </el-row>
                      <el-row>
                        <el-col>
                          <div class="row">
                            <div class="col-sm-12">
                              <el-table :data="invoices" border element-loading-text="Loading" style="width: 100%" v-loading="loading">
                                <el-table-column label="" align="center" width="50">
                                  <template slot-scope="scope">
                                    <input type="checkbox" v-on:change="selectInvoice(scope.row, $event, scope.$index)"/>
                                  </template>
                                </el-table-column>
                                <el-table-column align="center" label='Status' prop="id"  :min-width="120">
                                  <template slot-scope="scope">
                                      <div v-html="getPaymentStatus(scope.row)"></div>
                                  </template>
                                </el-table-column>
                                 <el-table-column align="center" label='Invoice #' prop="id"  width="120">
                                  <template slot-scope="scope">
                                  <el-tag slot="reference" size="mini">{{(scope.row.invoice_number) ? scope.row.invoice_number : ""}}</el-tag>
                                  </template>
                                </el-table-column>
                                <el-table-column align="center" label='Invoice Amount' prop="id"  width="120">
                                  <template slot-scope="scope">
                                  {{(scope.row.total_amount) ? '$'+scope.row.total_amount : '$0.00'}}
                                  </template>
                                </el-table-column>                               
                                <el-table-column align="center" label='Amount Due' prop="id"  width="120">
                                  <template slot-scope="scope">
                                  {{(scope.row.term_paid_amount) ? '$'+scope.row.term_paid_amount : '$0.00'}}
                                  </template>
                                </el-table-column>
                                <el-table-column align="center" label='Balance Due' prop="balance_amount"  width="120">
                                  <template slot-scope="scope">
                                    $<span v-html="getDueAmount(scope.row)"></span>                                                                   
                                  </template>
                                </el-table-column>
                                <el-table-column align="center" label='Payment' prop="payment_amount" width="150" fixed="right">
                                  <template slot-scope="scope">
                                    <el-input-number v-model="scope.row.payment"  style="width:100%;" :min="0"  :controls='false' 
                                    :max="parseFloat(getDueAmount(scope.row)).toFixed(2)"
                                    disabled>
                                    </el-input-number>
                                    <!-- :disabled="!scope.row.edited" -->
                                  </template>
                                </el-table-column>
                              </el-table>
                              <span class="form-err" v-if="form.errors.has('selected_invoice')" >{{form.errors.get('selected_invoice')}}</span>
                            </div>
                          </div>
                         </el-col>
                       </el-row>
                       <div class="d-flex justify-content-center border-top pt-5 ">
                            <a class="text-white" @click="payCustomerInvoice()">
                                <div class="btn btn-primary btn-sm font-weight-bold text-uppercase">
                                    Pay
                                </div>
                            </a>
                        </div>
                    </div>
            </div>
         </div>
      </div>
   </div>
 </div>
</template>

<script>
import Form from '@/core/services/api.form.services';
import {getCustomerInvoices,getCustomerRecordPayment} from "@/api/customer";
import { globalEvents } from '@/utils/globalEvents';
import {getPaymentMethods} from "@/api/invoice";
export default {
  name: 'add-store-credit',
   props: {
    customer_id: {
      type: Number
    },
    store_credit:{
      type: Object
    }
  },
  data() {
    return {
      loading:false,
      form: new Form({
        payment_date: new Date(),
        payment_method: null,
        customer_id: null,
        amount: 0.00,
        balance: null,
        bank_acount_no:null,
        cheque_no:null,
        transaction_id:null,
        payment_details:null,
        credit_balance:0,
        selected_invoice:[],        
        store_credit_id: 0,
        recordAmount:0.00,
      }),
      
      payment_methods:[],
      invoices: [],
      api_url: '/record/payment',
      response_msg: 'Record Payment has been added',
      submit_btn_text: 'Add',
      bank_account_error:null,
      cheque_error:null,
      email_transaction_error:null,
      select_invoice_status : false,
    }
  },
  components: {
  },
  
  methods: {
    reset() {
      this.form.reset()
      this.$refs['form'].resetFields()
    },
    closeForm() {
      if (!this.form.submiting) {
        this.$emit('close')
      }
    },
   
     checkAmount(amount){  
       console.log(this.invoices);    
      this.form.errors.add("amount", "");
      let due_amount = parseFloat(amount).toFixed(2).match(/^-?[0-9]*[.][0-9]+$/);
       if(due_amount != null){
        this.form.amount = parseFloat(due_amount).toFixed(2);
        let credit_bal = parseFloat(this.form.amount)+parseFloat(this.form.recordAmount);
        this.form.credit_balance = parseFloat(credit_bal).toFixed(2)      
      }else{
        this.form.amount = ''
        this.form.errors.add("amount", "Please Enter Amount Only");
      }       
    },
    getCustomerRecordPayment(){
        getCustomerRecordPayment(this.form.customer_id).then(response => {          
          this.form.recordAmount = response.data.data.balance   
          this.form.credit_balance = this.form.recordAmount       
        });
    },
    getCustomerInvoices(){
          getCustomerInvoices(this.form.customer_id).then(response => {
            let invoices = response.data.data;
            invoices.map((invoice) => {         
              invoice['payment']=0; 
              invoice['edited'] = false
            })
            this.invoices = invoices            
            this.loading = false
          });
     },
     getDueAmount(row){     
       let due_amount = 0;
        if(row.term_paid_amount>0){         
            due_amount = parseFloat(row.term_paid_amount) + parseFloat(row.balance_amount);            
        }else{
          due_amount = parseFloat(row.balance_amount).toFixed(2);
        }
        return parseFloat(due_amount).toFixed(2);
     },
       getPaymentStatus(row){
        let date = new Date(row.payment_due_date);
        var periods = {
            month: 30 * 24 * 60 * 60 * 1000,
            week: 7 * 24 * 60 * 60 * 1000,
            day: 24 * 60 * 60 * 1000,
            hour: 60 * 60 * 1000,
            minute: 60 * 1000
        };
        var diff = Date.now() - date.getTime();
        var status = "";
        var status_msg = "";
        if(row.invoice_status === 'partially_paid'){
            status_msg = '<span class="partially-span el-tag el-tag--mini el-tag--light">Partially Paid</span>';
        }else if(row.invoice_status == 'unpaid' && Math.floor(diff / periods.day) > 0){
            if (diff > periods.month) {
                status = Math.floor(diff / periods.month) + " month ago";
            } else if (diff > periods.week) {
                status = Math.floor(diff / periods.week) + " week ago";
            } else if (diff > periods.day) {
                status = Math.floor(diff / periods.day) + " days ago";
            } /*else if (diff > periods.hour) {
                status = Math.floor(diff / periods.hour) + " hours ago";
            } else if (diff > periods.minute) {
                status = Math.floor(diff / periods.minute) + " minutes ago";
            }*/
            status_msg = '<span class="el-tag el-tag--mini el-tag--danger">Over Due</span><div>'+status+'</div>';
        }else if(row.invoice_status === 'paid'){
          status_msg = '<span class="paid-span el-tag el-tag--mini el-tag--success">Paid</span>';
          if(row.term_paid_amount > 0){
            status_msg = '<span class="paid-span el-tag el-tag--mini el-tag--success">Term</span>';
          }
            
        }else if(row.invoice_status === 'unpaid'){
            status_msg = '<span class="el-tag el-tag--mini el-tag--danger">Unpaid</span>';
        }

        return status_msg;
    },
   
    getPaymentMethods(){
      this.loading = true
      getPaymentMethods().then(response => {
          this.payment_methods = response.data.data
          this.loading = false
      });
    },
      payCustomerInvoice(){        
      this.form.payment_date = this.$formatDate(this.form.payment_date, 'YYYY-MM-DD');      
      if(this.form.payment_method == 'Bank'){
        this.form.payment_details = this.form.bank_acount_no;
        this.bank_account_error = null;
        if(!this.form.bank_acount_no){
          this.bank_account_error = 'Bank account Number is required';
        } 
      }
      if(this.form.payment_method == 'Cheque'){
        this.form.payment_details = this.form.cheque_no;
        this.cheque_error = null;
        if(!this.form.cheque_no){
          this.cheque_error = 'Cheque Number is required';
        } 
      }
      if(this.form.payment_method == 'Email'){
        this.form.payment_details = this.form.transaction_id;
        this.email_transaction_error = null;
        if(!this.form.transaction_id){
          this.email_transaction_error = 'Transaction ID is required';
        } 
      }
      if(!this.bank_account_error || !this.cheque_error || !this.email_transaction_error){
      if(this.form.selected_invoice.length >0){
          this.$bvModal.msgBoxConfirm('Are you sure you want to proceed ?', {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
      }).then(value => {
          if (value) {
              this.loading = true;
              //'/invoice/pay/customer'
              
              this.form.post(this.api_url).then(response => {
                globalEvents.$emit('InvoicePaid', response)
                this.$emit('close')
                this.loading = false;
              }).catch(err => {
                console.log(err);
                this.loading = false;
              });
              
          } else {
              return false;
          }
      })
      }else{
         const h = this.$createElement;
            this.$notify({
              message: h(
                "i",
                {
                  style: "color: red",
                },
                "Please select invoice(s)"
              ),
              offset: 200,
              type: "warning",
            });
      } 
      }
    },
     handleActions(evt, row) {
      if (evt === 1) {
        console.log(row)
      } else if (evt === 2) {
        console.log(row)
      }
    },
    selectInvoice(row,event,index){
      let credit_balance = parseFloat(this.form.credit_balance) - this.getDueAmount(row);     
      if(credit_balance < 0 && this.form.credit_balance != 0){
        if (event.target.checked) {
           this.$bvModal.msgBoxConfirm('Insufficient balance. Need to pay partial payment ?', {
                    title: 'Please Confirm',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'YES',
                    cancelTitle: 'NO',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                }).then(value => {                 
                  if(value){
                     this.addInvoiceToSelected(row,event,index,'partial');
                     if(value == true){                     
                        event.target.checked = true;                        
                        this.setCreditBalance();
                     }
                  }else{
                    event.target.checked = false;                    
                  }
                });
          
        }else{
          this.addInvoiceToSelected(row,event,index, 'full');
        }
      }else{
          if(this.form.credit_balance != 0){
            this.addInvoiceToSelected(row,event,index, 'full');
          }else{
            if(event.target.checked){
              this.$bvModal.msgBoxConfirm('Insufficient balance.',  {
                title: 'Please Confirm',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Ok',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
              }).then(value => {
                  console.log(value)
                  event.target.checked = false;
                   
              })
            }else{
              this.addInvoiceToSelected(row,event,index, 'full');
            }
          }
      }
    },
     setCreditBalance(){
      let selected_invoice_amount = 0;     
      this.form.selected_invoice.map((invoice) => {       
        selected_invoice_amount = parseFloat(selected_invoice_amount) + parseFloat(invoice.payment);
      })
     
      let record_balance =parseFloat(this.form.amount) + parseFloat(this.form.recordAmount);
      this.form.credit_balance = parseFloat(record_balance - selected_invoice_amount).toFixed(2);
     
    },
    addInvoiceToSelected(row, event, index, type){
      if (event.target.checked) {
        let amount = (type === 'partial') ? this.form.credit_balance : this.getDueAmount(row)        
        this.invoices[index].payment = amount  
        this.invoices[index].edited = true    
        this.form.selected_invoice.push(this.invoices[index]);      
      }else{
        this.removeInvoiceFromSelected(row.id);
        this.invoices[index].payment = 0;
        this.invoices[index].edited = false
      }      
      this.setCreditBalance();
      if(this.form.selected_invoice.length>0){
        this.select_invoice_status = true
      }
    },
    removeInvoiceFromSelected(invoice_id){
      this.form.selected_invoice.map((invoice, index) => {
        if(invoice.id === invoice_id){
          this.form.selected_invoice.splice(index,1);
        }
      });
      console.log(this.form.selected_invoice.length);
      if(this.form.selected_invoice.length == 0){
        this.select_invoice_status = false
      }
    },
  },
  created() {
    this.form.customer_id = this.customer_id;
    this.getPaymentMethods();
    this.getCustomerInvoices();  
    this.getCustomerRecordPayment();
    if(this.store_credit && this.store_credit.id){     
      this.api_url = 'update/store/credit/'+this.store_credit.id;
      this.submit_btn_text = 'Update';
      this.response_msg = 'Store credit updated successfully.';
      this.form = new Form(this.store_credit);
    }
  }
}
</script>